import { Page } from "@shopify/polaris";
import BundlesTable from "./bundles-table.component";

function BundlesList() {
  return (
    <Page
      fullWidth
      title={"Bundles List"}
      primaryAction={{ content: "Add Bundle", url: "/admin/bundles/add" }}
    >
      <BundlesTable />
    </Page>
  );
}

export default BundlesList;
