import React from 'react'
import history from '../history'
import ShopProducts from './ShopProducts'
import ShopCoupons from './ShopCoupons'
import ShopShipping from './ShopShipping'
import NavigationLayout from '../components/NavigationLayout'
import { Switch, Route, Router } from "react-router-dom";
import ShopProduct from './ShopProduct'
import ShopCoupon from './ShopCoupon'
import ShopOrdersListing from './ShopOrdersListing'
import ShopOrder from './ShopOrder'
import ShopProductPrice from './ShopProductPrice'
import ShopSubscriptionsListing from './ShopSubscriptionsListing'

const Shop = () => {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/shop/orders" component={ShopOrdersListing} />
          <Route exact path="/admin/shop/orders/:id" component={ShopOrder} />
          <Route exact path="/admin/shop/products" component={ShopProducts} />
          <Route exact path="/admin/shop/coupons" component={ShopCoupons} />
          <Route exact path="/admin/shop/add-coupon" component={ShopCoupon} />
          <Route exact path="/admin/shop/coupon/:id/edit" component={ShopCoupon} />
          <Route exact path="/admin/shop/shipping" component={ShopShipping} />
          <Route exact path="/admin/shop/add" component={ShopProduct} />
          <Route exact path="/admin/shop/product/:id/prices/:optionId" component={ShopProductPrice} />
          <Route exact path="/admin/shop/product/:id/edit" component={ShopProduct} />
          <Route exact path="/admin/shop/subscriptions" component={ShopSubscriptionsListing} />
        </Switch>
      </Router>
    </NavigationLayout>
  )
}

export default Shop