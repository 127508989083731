import {
  Button,
  ButtonGroup,
  Card,
  DataTable,
  DisplayText,
  Image,
  Spinner,
  TextStyle,
} from "@shopify/polaris";
import React, { useContext, useEffect } from "react";
import { BundlesContext } from "../context/bundles.context";
import history from "../history";
import emptyIcon from "../img/emptyList.svg";
import { capitalizeWords } from "../util/helpers";

function BundlesTable() {
  const {
    states: { bundles },
    actions: { getBundles },
  } = useContext(BundlesContext);

  const handleEdit = (id) => {
    history.push(`/admin/bundles/${id}/edit`);
  };

  const emptyState = bundles.loading ? (
    <div className="spinnerContainer">
      <div className="vertical-center">
        <Spinner accessibilityLabel="Spinner example" size="large" />
      </div>
    </div>
  ) : (
    !bundles.items.length && (
      <div className="message-no-result">
        <div className="message-no-result-img">
          <Image source={emptyIcon} alt="empty result image"></Image>
        </div>
        <DisplayText size="small">No results found</DisplayText>
        <br />
      </div>
    )
  );

  useEffect(() => {
    getBundles();
  }, []);

  let items = [];

  if (bundles.items.length) {
    items = bundles.items.map((item) => [
      item.id,
      capitalizeWords(item.title.en),
      capitalizeWords(item.status),
      capitalizeWords(item.tag),
      item.pos,
      <ButtonGroup>
        <Button id={String(item.id)} onClick={() => handleEdit(item.id)}>
          Edit
        </Button>
      </ButtonGroup>,
    ]);
  }

  return (
    <Card>
      <DataTable
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
        ]}
        headings={[
          <TextStyle variation="strong">ID</TextStyle>,
          <TextStyle variation="strong">Title</TextStyle>,
          <TextStyle variation="strong">Status</TextStyle>,
          <TextStyle variation="strong">Tag</TextStyle>,
          <TextStyle variation="strong">Position</TextStyle>,
          <TextStyle variation="strong"></TextStyle>,
        ]}
        rows={items}
        sortable={[false, false, false, false, false, false]}
        defaultSortDirection="descending"
        initialSortColumnIndex={0}
        verticalAlign="center"
      />
      {emptyState}
    </Card>
  );
}

export default BundlesTable;
