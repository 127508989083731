import { Route, Router, Switch } from "react-router-dom";
import history from "../history";
import NavigationLayout from "../components/NavigationLayout";
import BundlesList from "./bundles-list-component";
import Bundle from "./bundle.component";

function Bundles() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/bundles/add" component={Bundle} />
          <Route exact path="/admin/bundles/:id/edit" component={Bundle} />
          <Route exact path="/admin/bundles" component={BundlesList} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}

export default Bundles;
