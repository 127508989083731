import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { UserProvider,UserContext } from "./UserContext";
import { ShopProvider } from "./ShopContext";
import { BundlesProvider } from './context/bundles.context';
// import { collection, doc, getDoc, getDocs, query, updateDoc, where  } from "firebase/firestore";
// import {messaging, db, auth} from "./firebase";
// import Cookies from "js-cookie";
// import { onMessage ,getToken} from 'firebase/messaging';


// Notification.requestPermission().then(() => {
//   getTokenAndRefresh();
//   console.log("Notification permission granted.");
// }).catch((err) => {
//   console.log("Unable to get permission to notify.", err);
// });

// setInterval(() => {
//   if (Notification.permission !== "granted") {
//     console.log("Notifications are disabled. Prompting user to re-enable them.");
//     Notification.requestPermission().then(permission => {
//       if (permission === "granted") {
//         getTokenAndRefresh();
//         console.log("Notifications have been re-enabled.");
//       }
//     });
//   }else{
//     getTokenAndRefresh()
//   }
// }, 1800000); // 30 minutes
// const handleIncomingMessage =(payload) => {
//   console.log("payload",payload)
//   const notificationOptions = {
//     body: payload.notification.body,
//     icon:"./BasmaNotification.png",
//     data:{
//       path:payload.data.path,
//     }
//   };
//   let notification = new Notification(payload.notification.title,notificationOptions)
//   notification.onclick = (event)=>{
//     console.log(event,payload)
//     const clickAction = event.target.data.path;
//     window.open(clickAction,'_blank')
//   }
// }
// onMessage(messaging, handleIncomingMessage)
// const getTokenAndRefresh = async () => {
//   try {
//     let token = await getToken(messaging,{vapidKey:process.env.REACT_APP_VAPID_KEY})
//     let adminId = Cookies.get('admin_id')
//     if (adminId) {
//         const adminCollection = collection(db,"Admins");
//         const adminQuery = query(adminCollection, where('admin_id','==',parseInt(adminId)))
//         getDocs(adminQuery).then((adminRef)=>{
//           if(adminRef.size>0){
//             let admin = adminRef.docs[0];
//             if(token != admin.data().fcm_token){
//               updateDoc(doc(db,"Admins",admin.id), {fcm_token : token });
//             }
//           }
//         })
//     };
//   } catch (error) {
//     console.log('An error occurred while retrieving token.', error);
//   }
// };





ReactDOM.render(
  <UserProvider>
    <ShopProvider>
      <BundlesProvider>
        <App />
      </BundlesProvider>
    </ShopProvider>
  </UserProvider>,
  document.getElementById("root")
);
