import {
  Card,
  FormLayout,
  Layout,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import React from "react";
function BundlePopupSettings({ bundleTitle, handleBundleTitleChange }) {
  const { ar, en } = bundleTitle;
  return (
    <Layout.AnnotatedSection title="Bundle Popup Details">
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Title English</TextStyle>
          <TextField
            onChange={(value) => handleBundleTitleChange("en", value)}
            value={en}
            labelHidden
            label={"Title English"}
          />
          <TextStyle variation="strong">Title Arabic</TextStyle>
          <TextField
            value={ar}
            onChange={(value) => handleBundleTitleChange("ar", value)}
            labelHidden
            label={"Title Arabic"}
          />
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
}

export default BundlePopupSettings;
