import React from "react";
import { Card, FormLayout, TextField, TextStyle } from "@shopify/polaris";
import Select from "react-select";
import BundleItemPrices from "./bundle-item-prices.component";
import BundleItemOptions from "./bundle-item-options.component";

function BundleItemForm({
  index,
  item,
  handleBundleItemChange,
  onRemove,
  shopProductsOptions,
  loading,
  itemsPositionsOptions,
  procedureTypesOptions,
  typeOptions,
  handleRemovePrice,
  handleChangePrice,
  shopProductPrices,
}) {
  let itemTypeOptions = [];
  let showProductFields = false;
  if (item.type === "shop_product") {
    itemTypeOptions = shopProductsOptions;
    showProductFields = true;
  } else if (item.type === "procedure") {
    itemTypeOptions = procedureTypesOptions;
  }
  return (
    <Card sectioned actions={[{ content: "Remove Item", onAction: onRemove }]}>
      <FormLayout>
        <FormLayout.Group>
          <FormLayout>
            <TextStyle variation="strong">Type</TextStyle>
            <Select
              label="Type"
              options={typeOptions}
              value={
                typeOptions.find((option) => option.value === item.type) || ""
              }
              onChange={(selectedOption) =>
                handleBundleItemChange(index, "type", selectedOption.value)
              }
              isLoading={loading}
              menuPosition="fixed"
              menuPlacement="auto"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
            />
          </FormLayout>
        </FormLayout.Group>
        {item.type && (
          <FormLayout>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Item Type</TextStyle>
                <Select
                  label="Procedure Type"
                  options={itemTypeOptions}
                  value={
                    itemTypeOptions.find(
                      (option) => option.value === item.itemType,
                    ) || ""
                  }
                  onChange={(selectedOption) =>
                    handleBundleItemChange(
                      index,
                      "itemType",
                      selectedOption.value,
                    )
                  }
                  isLoading={loading}
                  menuPosition="fixed"
                  menuPlacement="auto"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                  }}
                />
              </FormLayout>
            </FormLayout.Group>
            {item.type === "shop_product" ? (
              <BundleItemOptions
                shopProductPrices={shopProductPrices}
                handleBundleItemChange={handleBundleItemChange}
                bundleItem={item}
                bundleIndex={index}
                handleRemovePrice={handleRemovePrice}
                handleChangePrice={handleChangePrice}
              />
            ) : (
              <BundleItemPrices
                bundleItem={item}
                bundleIndex={index}
                handleRemovePrice={handleRemovePrice}
                handleChangePrice={handleChangePrice}
              />
            )}

            <FormLayout.Group>
              {showProductFields && (
                <FormLayout>
                  <TextStyle variation="strong">Quantity</TextStyle>
                  <TextField
                    label="Quantity"
                    labelHidden
                    type="number"
                    value={item.quantity}
                    onChange={(value) =>
                      handleBundleItemChange(index, "quantity", value)
                    }
                  />
                </FormLayout>
              )}
              <FormLayout>
                <TextStyle variation="strong">Position</TextStyle>
                <Select
                  label="Position"
                  options={itemsPositionsOptions}
                  value={
                    itemsPositionsOptions.find(
                      (option) => option.value === item.position,
                    ) || ""
                  }
                  onChange={(selectedOption) =>
                    handleBundleItemChange(
                      index,
                      "position",
                      selectedOption.value,
                    )
                  }
                  isLoading={loading}
                  menuPosition="fixed"
                  menuPlacement="auto"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                  }}
                />
              </FormLayout>
            </FormLayout.Group>
          </FormLayout>
        )}
      </FormLayout>
    </Card>
  );
}

export default BundleItemForm;
