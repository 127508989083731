import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => {
    return {
      reviewBoldTxt: {
        fontWeight: 'bold',
      },
      size11: {
        fontSize: '11px',
      },
    };
});

const UploadedBy = ({ ownerName }) => {
    const classes = useStyles();
    return (
        <Typography className={classes.size11}>
            <span className={classes.reviewBoldTxt}>Uploaded By:</span>{" "}
            <span>{ownerName}</span>
        </Typography>
    )
}

export default UploadedBy
