import React, { useState } from 'react'
import {
    TextField,
    Button as PolarisButton,
} from "@shopify/polaris";
import ApprovalActions from "./ApprovalActions";
import ScreeningPhotoUpload from "./ScreeningPhotoUpload";
import axios from "../axios";
import axiosAws from "axios";
import Cookies from "js-cookie";
import { caseRecordTypes, ReviewStatus } from '../util/constants';
import UploadedBy from './UploadedBy';

const PostIprPhotoData = ({
    classes,
    setPhotoId,
    data,
    handleReviewStatus,
    setReviewData,
    setErrorFilesUpload,
    delayedActionJob,
    hasDelayedAction,
    handleSave,
    setOpen,
    postIprPhotosIcon,
    setImageKey,
    appointmentType,
    parentId,
}) => {
    const [uploadProgress, setUploadProgress] = useState({});
    const [disableReviewBtn, setDisableReviewBtn] = useState(false);
    const [files, setFiles] = useState(data?.user_post_ipr_files);
    const [note, setNote] = useState(data?.note);

    const handleChange = (prop, newValue) => {
        setImageKey({
            id: data.id,
            [prop]: newValue,
        });
        setNote(newValue);
    }

    const handleChangeReview = (status) => {
        setDisableReviewBtn(status == ReviewStatus.approved);
        if (status == ReviewStatus.approved) {
            handleReviewStatus(status, data.id);
        } else {
            setReviewData(prev => ({
                ...prev,
                id: data.id,
                modalOpened: true,
                status,
            }));
        }
    }

    const uploadConfig = (progressEvent, name) => {
        if (progressEvent.loaded === progressEvent.total) {
            delete uploadProgress[`image_${name}`];
            setUploadProgress(uploadProgress);
        } else {
            const array = {
                ...uploadProgress,
                [`image_${name}`]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
            };
            setUploadProgress(array);
        }
    };

    const handleUploadError = (err) => {
        setErrorFilesUpload('Upload failed. Please try again.');
        setImageKey(null);
        console.log(err);
    };

    const handleImages = (e, id) => {
        setErrorFilesUpload('');
        const file = e.target.files[0];
        let imageType = file.type;
        const form_data = new FormData();
        form_data.append("content_type", imageType);
        form_data.append("file_name", file.name);
        axios.post("/admin/v1/images/s3", form_data, {
            headers: {
                Authorization: "Bearer " + Cookies.get("token"),
            },
        }).then((res) => {
            let imageData = {
                id: data.id,
                image: {
                    id,
                    file: res.data.key,
                    file_type: imageType,
                }
            };
            const url2 = res.data.url;
            axiosAws.put(url2, file, {
                headers: {
                    "x-amz-acl": "public-read-write",
                    "Content-Type": imageType,
                },
                onUploadProgress: (event) => uploadConfig(event, id),
            }).then(() => {
                handleSave(imageData);
            }).catch((err) => {
                handleUploadError(err);
            });
        }).catch((err) => {
            handleUploadError(err);
        });
    };

    const handleAddFiles = () => {
        setFiles((prev) => [...prev, {}]);
    };

    const handleClickOpen = (id) => {
        setPhotoId(id);
        setOpen(true);
    };

    return (
        <div>
            <div style={{ textAlign: "right", marginTop: "1rem" }}>
                <PolarisButton onClick={handleAddFiles} disabled={
                    hasDelayedAction ||
                    delayedActionJob.disabledRecord === `${caseRecordTypes.UserCaseRecordPostIprPhoto}-${parentId}` ||
                    data?.review_status != null
                }>Add another file</PolarisButton>
            </div>
            <div className={classes.mediaWrapper}>
                {
                    files.map((image, key) => (
                        <ScreeningPhotoUpload
                            key={key}
                            disabled={
                                hasDelayedAction ||
                                delayedActionJob.disabledRecord === `${caseRecordTypes.UserCaseRecordPostIprPhoto}-${parentId}` ||
                                data?.review_status != null
                            }
                            value={image?.file_url ? image?.file_url : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'}
                            fileType={image?.file_type}
                            handleImages={handleImages}
                            id={image?.id ? image?.id : 0}
                            uploadProgress={uploadProgress[`image_${image?.id}`]}
                            handleDelete={() => handleClickOpen(image?.id)}
                            deleteDisabled={
                                hasDelayedAction ||
                                delayedActionJob.disabledRecord === `${caseRecordTypes.UserCaseRecordPostIprPhoto}-${parentId}` ||
                                data?.review_status != null
                            }
                            imagesArray={data?.user_post_ipr_files.map(img => ({ url: img?.file_url, id: img?.id, }))}
                            identifier={`ipr-${appointmentType?.procedure}`}
                        />
                    ))
                }
            </div>
            <TextField
                label="Note"
                value={note}
                onChange={(e) => handleChange('note', e)}
                multiline={4}
                autoComplete=""
                disabled={
                    hasDelayedAction ||
                    delayedActionJob.disabledRecord === `${caseRecordTypes.UserCaseRecordPostIprPhoto}-${parentId}` ||
                    data?.review_status != null
                }
            />
            <br />
            <UploadedBy ownerName={data?.owner_name} />
            <br />
            <ApprovalActions
                disabled={
                    hasDelayedAction ||
                    delayedActionJob.disabledRecord === `${caseRecordTypes.UserCaseRecordPostIprPhoto}-${parentId}` ||
                    postIprPhotosIcon == 0 ||
                    !files.length ||
                    disableReviewBtn ||
                    data?.review_status != null
                }
                reviewStatus={data?.review_status}
                reviewer={data?.reviewer}
                reviewedAt={data?.reviewed_at}
                handleChangeReview={handleChangeReview}
            />
            {
                data?.replaced_file && (
                    <PostIprPhotoData
                        data={data.replaced_file}
                        classes={classes}
                        setPhotoId={setPhotoId}
                        handleReviewStatus={handleReviewStatus}
                        setReviewData={setReviewData}
                        setErrorFilesUpload={setErrorFilesUpload}
                        hasDelayedAction={hasDelayedAction}
                        delayedActionJob={delayedActionJob}
                        handleSave={handleSave}
                        setOpen={setOpen}
                        postIprPhotosIcon={postIprPhotosIcon}
                        setImageKey={setImageKey}
                        appointmentType={appointmentType}
                        parentId={parentId}
                    />
                )
            }
        </div>
    )
}

export default PostIprPhotoData
