import { Card } from "@shopify/polaris";
import BundleItemForm from "./bundle-item-form.component";

function BundleItem({
  shopProductsOptions,
  loading,
  itemsPositionsOptions,
  procedureTypesOptions,
  typeOptions,
  bundleItems,
  addBundleItem,
  removeBundleItem,
  handleBundleItemChange,
  handleRemovePrice,
  handleChangePrice,
  shopProductPrices,
}) {
  return (
    <Card
      sectioned
      title="Add Bundle Item"
      actions={[{ content: "Add Bundle Item", onAction: addBundleItem }]}
    >
      <>
        {bundleItems.map((item, index) => (
          <BundleItemForm
            loading={loading}
            key={index}
            index={index}
            item={item}
            handleBundleItemChange={handleBundleItemChange}
            onRemove={() => removeBundleItem(index)}
            shopProductsOptions={shopProductsOptions}
            itemsPositionsOptions={itemsPositionsOptions}
            procedureTypesOptions={procedureTypesOptions}
            typeOptions={typeOptions}
            handleRemovePrice={handleRemovePrice}
            handleChangePrice={handleChangePrice}
            shopProductPrices={shopProductPrices}
          />
        ))}
      </>
    </Card>
  );
}

export default BundleItem;
