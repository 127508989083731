import { Button, FormLayout, TextField, TextStyle } from "@shopify/polaris";
function BundleItemPrices({
  handleRemovePrice,
  bundleIndex,
  bundleItem,
  handleChangePrice,
}) {
  const renderShopProductPrices = () => {
    return bundleItem.prices.map((price, index) => (
      <FormLayout.Group key={index}>
        <FormLayout>
          <TextStyle variation="strong">Currency</TextStyle>
          <TextField
            label="Currency"
            labelHidden
            value={price.currency}
            readOnly
          />
        </FormLayout>
        <FormLayout>
          <TextStyle variation="strong">Country</TextStyle>
          <TextField
            label="Country"
            labelHidden
            value={price.country}
            readOnly
          />
        </FormLayout>
        <FormLayout>
          <TextStyle variation="strong">Price</TextStyle>
          <TextField
            label="Price"
            labelHidden
            type="number"
            value={price.amount.toString()}
            onChange={(value) =>
              handleChangePrice(bundleIndex, bundleItem, index, "amount", value)
            }
          />
        </FormLayout>
        <Button
          size="slim"
          destructive
          onClick={() => handleRemovePrice(bundleIndex, bundleItem, index)}
          disabled={bundleItem.prices.length === 1}
        >
          Remove
        </Button>
      </FormLayout.Group>
    ));
  };

  const renderProcedurePrices = () => {
    return bundleItem.prices.map((price, index) => (
      <FormLayout.Group key={index}>
        <FormLayout>
          <TextStyle variation="strong">Clinic</TextStyle>
          <TextField
            label="Clinic"
            labelHidden
            value={price.clinic_name}
            readOnly
          />
        </FormLayout>
        <FormLayout>
          <TextStyle variation="strong">Procedure Price</TextStyle>
          <TextField
            label="Procedure Price"
            labelHidden
            type="number"
            value={price.amount.toString()}
            onChange={(value) =>
              handleChangePrice(bundleIndex, bundleItem, index, "amount", value)
            }
          />
        </FormLayout>
        <FormLayout>
          <div style={{ visibility: "hidden" }}>
            <TextStyle variation="strong">Procedure Price</TextStyle>
          </div>
          <Button
            destructive
            disabled={bundleItem.prices.length === 1}
            onClick={() => handleRemovePrice(bundleIndex, bundleItem, index)}
          >
            Remove
          </Button>
        </FormLayout>
      </FormLayout.Group>
    ));
  };

  return (
    <FormLayout>
      {bundleItem.type === "shop_product" && renderShopProductPrices()}
      {bundleItem.type === "procedure" && renderProcedurePrices()}
    </FormLayout>
  );
}

export default BundleItemPrices;
