import React, { useCallback, useState, useEffect } from "react";
import {
  Page,
  Card,
  Layout,
  Modal,
} from "@shopify/polaris";
import history from "./history";
import {
  FormLayout,
  Checkbox,
  Button,
  InlineError,
  TextStyle,
  TextField,
  Caption,
  DropZone,
  Stack,
  Thumbnail,
  PageActions,
  Toast,
  DataTable,
  Badge,
  Pagination,
} from "@shopify/polaris";
import { Editor } from "@tinymce/tinymce-react";
import axios from "./axios";
import Cookie from "js-cookie";
import Select from "react-dropdown-select";
import Technician from "./Settings/technician.component";
import BundlePopupSettings from './bundle-popup-settings.component';

function Settings() {
  const [bundleTitle, setBundleTitle] = useState({ar: "", en: ""});
  const [imageKey, setImageKey] = useState("");
  let varBoolUpload = true;
  let roleId = [];
  if(Cookie.get("roleId"))
    roleId = JSON.parse(Cookie.get("roleId"));
  const [firstTimeOpened, setFirstTimeOpened] = useState(0);
  const [checked, setChecked] = useState(false);
  const [valueHomeTextEn, setValueHomeTextEn] = useState("");
  const [valueHomeTextAr, setValueHomeTextAr] = useState("");
  const [valueButtonTextEn, setValueButtonTextEn] = useState("");
  const [valueButtonTextAr, setValueButtonTextAr] = useState("");
  const [valueButtonLink, setValueButtonLink] = useState("");
  const [valueFacebookLink, setValueFacebookLink] = useState("");
  const [valueInstagramLink, setValueInstagramLink] = useState("");
  const [valueSnapchatLink, setValueSnapchatLink] = useState("");
  const [valueYoutubeLink, setValueYoutubeLink] = useState("");
  const [valuePhoneNumber, setValuePhoneNumber] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [valueEditorNotificationEn, setValueEditorNotificationEn] =
    useState("");
  const [valueEditorNotificationAr, setValueEditorNotificationAr] =
    useState("");
  const [valueEditorHomeTitleEn, setValueEditorHomeTitleEn] = useState("");
  const [valueEditorHomeTitleAr, setValueEditorHomeTitleAr] = useState("");
  const [valueEditorHomeSubtitleEn, setValueEditorHomeSubtitleEn] =
    useState("");
  const [valueEditorHomeSubtitleAr, setValueEditorHomeSubtitleAr] =
    useState("");
  const [usersItems, setUsersItems] = useState([]);
  const [valueSAR, setValueSAR] = useState("");
  const [valueLBP, setValueLBP] = useState("");
  const [valueUSD, setValueUSD] = useState("1");
  const [valueSwissFranc, setValueSwissFranc] = useState("");
  const [valueEuro, setValueEuro] = useState("");

  const [valueTitleEnSar, setValueTitleEnSar] = useState("");
  const [valueTitleArSar, setValueTitleArSar] = useState("");
  const [valueTitleEnLbp, setValueTitleEnLbp] = useState("");
  const [valueTitleArLbp, setValueTitleArLbp] = useState("");
  const [valueTitleEnUsd, setValueTitleEnUsd] = useState("");
  const [valueTitleArUsd, setValueTitleArUsd] = useState("");
  const [valueTitleEnSwissFranc, setValueTitleEnSwissFranc] = useState("");
  const [valueTitleArSwissFranc, setValueTitleArSwissFranc] = useState("");
  const [valueTitleEnEuro, setValueTitleEnEuro] = useState("");
  const [valueTitleArEuro, setValueTitleArEuro] = useState("");
  const [conversionItems, setConversionItems] = useState([]);

  let statusColors = ["default", "success", "info", "attention", "warning"];

  const [imagePreview, setImagePreview] = useState("");
  let lengthFiles = 0;
  let imageType = "";

  let currencyConverter = {};

  const [files, setFiles] = useState([]);
  
  const handleBundleTitleChange = (field, value) => {
    setBundleTitle({ ...bundleTitle, [field]: value });
  }

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  function dropAccepted() {}

  function finishUpload() {
    if (files[files.length - 1] != undefined) {
      imageType = files[files.length - 1].type;
    }
  }
  const [optionsOfProductsAndPlans, setOptionsOfProductsAndPlans] = useState(
    []
  );
  const [optionsUsers, setOptionsUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  let listUsers = [];
  let listOfProducts = [];
  let listOfPlans = [];
  const [optionsProducts, setOptionsProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [valuePayfortAmount, setValuePayfortAmount] = useState("");
  const [generatedPayfortLink, setGeneratedPayfortLink] = useState(null);
  const [valuePromocode, setValuePromocode] = useState("");
  const [currentPageAdminUsers, setCurrentPageAdminUsers] = useState(1);
  const [totalPagesAdminUsers, setTotalPagesAdminUsers] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const handleEditorChangeNotificationEn = (e) => {
    setValueEditorNotificationEn(e.target.getContent());
  };
  const handleEditorChangeNotificationAr = (e) => {
    setValueEditorNotificationAr(e.target.getContent());
  };
  const handleEditorChangeHomeTitleEn = (e) => {
    setValueEditorHomeTitleEn(e.target.getContent());
  };
  const handleEditorChangeHomeTitleAr = (e) => {
    setValueEditorHomeTitleAr(e.target.getContent());
  };
  const handleEditorChangeHomeSubtitleEn = (e) => {
    setValueEditorHomeSubtitleEn(e.target.getContent());
  };
  const handleEditorChangeHomeSubtitleAr = (e) => {
    setValueEditorHomeSubtitleAr(e.target.getContent());
  };

  function handleEdit(e) {
    Cookie.set("clickedCurrencyID", e.currentTarget.id);
    history.push("/admin/settings/editcurrency");
  }
  function getData() {
    axios
      .get("/admin/v1/settings", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        const resultData = result.data.data;
        currencyConverter = result.data.data.currency_converter;
        setValueEditorNotificationEn(result.data.data.promo_header_text.en);
        setValueEditorNotificationAr(result.data.data.promo_header_text.ar);
        setChecked(result.data.data.promo_header_status);
        result.data.data.others.promo_code &&
          setValuePromocode(result.data.data.others.promo_code);
        result?.data?.data?.promo_header_starting_time &&
          setStartDateValue(
            result.data.data.promo_header_starting_time.split(" ")[0]
          );
        result?.data?.data?.promo_header_starting_time &&
          setStartTimeValue(
            result.data.data.promo_header_starting_time.split(" ")[1]
          );

        result?.data?.data?.promo_header_ending_time &&
          setEndDateValue(
            result.data.data.promo_header_ending_time.split(" ")[0]
          );
        result?.data?.data?.promo_header_ending_time &&
          setEndTimeValue(
            result.data.data.promo_header_ending_time.split(" ")[1]
          );
        var arrayOfObjects = Object.keys(
          result.data.data.currency_converter
        ).map(function (key) {
          return { [key]: result.data.data.currency_converter[key] };
        });
        let arrayOfKeys = Object.keys(result.data.data.currency_converter);
        setItemsCurrencies(
          arrayOfObjects.map((item, index) => [
            arrayOfKeys[index],
            item[Object.keys(item)[0]].rate,
            item[Object.keys(item)[0]].en,
            item[Object.keys(item)[0]].ar,
            <Button
              id={String(arrayOfKeys[index])}
              onClick={(e) => handleEdit(e)}
            >
              Edit
            </Button>,
          ])
        );
        setValueFacebookLink(result.data.data.social_media.Facebook);
        setValueInstagramLink(result.data.data.social_media.Instagram);
        setValueSnapchatLink(result.data.data.social_media.Snapchat);
        setValueYoutubeLink(result.data.data.social_media.Youtube);
        setValuePhoneNumber(result.data.data.contact_phone);
        setValueEmail(result.data.data.contact_email);
        if (resultData?.bundle_popup_details) {
          setBundleTitle(resultData.bundle_popup_details);
        }
        // setValueSAR(result.data.data.currency_converter.sar.rate);
        // setValueLBP(result.data.data.currency_converter.lbp.rate);
        // setValueTitleEnSar(result.data.data.currency_converter.sar.en);
        // setValueTitleArSar(result.data.data.currency_converter.sar.ar);
        // setValueTitleEnLbp(result.data.data.currency_converter.lbp.en);
        // setValueTitleArLbp(result.data.data.currency_converter.lbp.ar);
        // setValueTitleEnUsd(result.data.data.currency_converter.usd.en);
        // setValueTitleArUsd(result.data.data.currency_converter.usd.ar);
        // setValueSwissFranc(result.data.data.currency_converter.swissfranc.rate);
        // setValueEuro(result.data.data.currency_converter.euro.rate);
        // setValueTitleEnSwissFranc(
        //   result.data.data.currency_converter.swissfranc.en
        // );
        // setValueTitleArSwissFranc(
        //   result.data.data.currency_converter.swissfranc.ar
        // );
        // setValueTitleEnEuro(result.data.data.currency_converter.euro.en);
        // setValueTitleArEuro(result.data.data.currency_converter.euro.ar);
        // setImagePreview(result.data.data.home_image);
        // setConversionItems([
        //   [
        //     "1",
        //     <TextField value={valueSAR} onChange={handleChangeSAR} />,
        //     <TextField value={valueLBP} onChange={handleChangeLBP} />
        //   ]
        // ]);
      })
      .catch((err) => console.log(err));

    axios
      .get("admin/v1/users?per_page=10&page=1", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        //setSelectedUser(String(result.data.data.data[0].id));
        for (var i = 0; i < result.data.data.data.length; i++) {
          listUsers.push({
            label:
              result.data.data.data[i].first_name +
              " " +
              result.data.data.data[i].last_name +
              " ( ID : " +
              result.data.data.data[i].id +
              " )",
            value: String(result.data.data.data[i].id),
          });
        }
        setOptionsUsers(listUsers);
      })
      .catch((err) => console.log(err));
    let listProductsAndPlans = [];
    axios
      .get("admin/v1/products", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        let res = result.data.data.data.filter((item) => item.active === true);
        for (var i = 0; i < res.length; i++) {
          listProductsAndPlans.push({
            value: String(res[i].id) + "_product",
            label: res[i].title.en,
          });
        }
        axios
          .get("admin/v1/plans", {
            headers: {
              Authorization: "Bearer " + Cookie.get("token"),
            },
          })
          .then((result) => {
            let resplans = result.data.data.filter(
              (item) => item.active === true
            );
            for (var i = 0; i < resplans.length; i++) {
              listProductsAndPlans.push({
                value: String(resplans[i].id) + "_plan",
                label: resplans[i].title.en,
              });
            }
            //setSelectedProduct("2");

            setOptionsOfProductsAndPlans(listProductsAndPlans);
          })

          .catch((err) => console.log(err));
      })

      .catch((err) => console.log(err));
  }
  const [openFileDialog, setOpenFileDialog] = useState(false);

  const sendImage = useCallback(
    (files) => {
      setPopupActive(true);
      imageType = files[files.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setImageKey(res.data.key);
          //   imageKey = res.data.key
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setImagePreview(
                validImageTypes.indexOf(files[files.length - 1].type) > 0
                  ? window.URL.createObjectURL(files[files.length - 1])
                  : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
              );
              successUploadImage();
              setPopupActive(false);
              //  setVarBoolUpload(true)
              // setFiles([])
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [files]
  );

  const [active, setActive] = useState(false);
  const [toastContent, setToastContent] = useState("Settings Saved");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastContent} onDismiss={toggleActive} />
  ) : null;

  const [fieldRequiredE, setFieldRequiredE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredP, setFieldRequiredP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [isUploadingAtive, setIsUploadingAtive] = useState(false);
  const [popupActive, setPopupActive] = useState(false);

  const handleChange = useCallback(() => setActive(!active), [active]);
  const [itemsCurrencies, setItemsCurrencies] = useState([]);
  const [fieldRequiredProduct, setFieldRequiredProduct] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  let i = 0;
  const [oldSearchValue, setOldSearchValue] = useState("");

  function searchUser(value) {
    console.log("i==", i);
    if (i === 0) {
      i = 1;
      console.log("searchUser=", value);
      // axios
      //   .get(
      //     `admin/v1/users?
      //     filter[first_name]=${value}`,
      //     {
      //       headers: {
      //         Authorization: "Bearer " + Cookie.get("token"),
      //       },
      //     }
      //   )
      //   .then((result) => {
      //     for (var i = 0; i < result.data.data.data.length; i++) {
      //       listUsers.push({
      //         label:
      //           result.data.data.data[i].first_name +
      //           " " +
      //           result.data.data.data[i].last_name +
      //           " ( ID : " +
      //           result.data.data.data[i].id +
      //           " )",
      //         value: String(result.data.data.data[i].id),
      //       });
      //     }
      //     setOptionsUsers(listUsers);
      //   })
      //   .catch((err) => console.log(err));
    }
  }
  // function onInputRenderer(value) {
  //   console.log("onInputRenderer=", value);
  // }
  const [startDateValue, setStartDateValue] = useState("");
  const handleChangeStartDate = useCallback(
    (newValue) => setStartDateValue(newValue),
    []
  );
  const [startTimeValue, setStartTimeValue] = useState("");
  const handleChangeStartTime = useCallback(
    (newValue) => setStartTimeValue(newValue),
    []
  );
  const [endDateValue, setEndDateValue] = useState("");
  const handleChangeEndDate = useCallback(
    (newValue) => setEndDateValue(newValue),
    []
  );
  const [endTimeValue, setEndTimeValue] = useState("");
  const handleChangeEndTime = useCallback(
    (newValue) => setEndTimeValue(newValue),
    []
  );
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    axios
      .get(
        `/admin/v1/management/users?per_page=${perPage}&page=${currentPageAdminUsers}`,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        setTotalPagesAdminUsers(Math.ceil(result.data.data.total / perPage));
        setUsersItems(
          result.data.data.data.map((item) => [
            item.id,
            item.first_name,
            item.last_name,
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', gap: '5px' }}>
              {item.roles.length > 0 && item.roles.map((res) => (
                  <Badge>
                    {res.name == 'clinic-moderator' ? 'clinic moderator' : res.name }
                  </Badge>
              ))}
            </div>,
          ])
        );
      })
      .catch((err) => console.log(err));
  }, [currentPageAdminUsers]);
  return (
    <Page>
      <Layout>
        <BundlePopupSettings
          handleBundleTitleChange={handleBundleTitleChange}
          bundleTitle={bundleTitle}
        />
        <Layout.AnnotatedSection title="Banner Notification">
          <Card sectioned>
            <FormLayout>
              <TextStyle variation="strong">Notification En</TextStyle>

              <Editor
                apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
                value={valueEditorNotificationEn}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onChange={handleEditorChangeNotificationEn}
              />

              <TextStyle variation="strong">Notification Ar</TextStyle>

              <Editor
                apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
                value={valueEditorNotificationAr}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
alignleft aligncenter alignright alignjustify | \
bullist numlist outdent indent | removeformat | help",
                }}
                onChange={handleEditorChangeNotificationAr}
              />

              <TextStyle variation="strong">Promo Code</TextStyle>
              <TextField
                value={valuePromocode}
                onChange={handleChangePromocode}
              />
              <FormLayout.Group>
                <FormLayout>
                  <TextField
                    label="Start Date"
                    value={startDateValue}
                    onChange={handleChangeStartDate}
                    autoComplete="off"
                    type="date"
                  />
                  <TextField
                    label="End Date"
                    value={endDateValue}
                    onChange={handleChangeEndDate}
                    autoComplete="off"
                    type="date"
                  />
                </FormLayout>
                <FormLayout>
                  <TextField
                    label="Start Time"
                    value={startTimeValue}
                    onChange={handleChangeStartTime}
                    autoComplete="off"
                    type="time"
                  />

                  <TextField
                    label="End Time"
                    value={endTimeValue}
                    onChange={handleChangeEndTime}
                    autoComplete="off"
                    type="time"
                  />
                </FormLayout>
              </FormLayout.Group>

              <Checkbox
                label="Is Active"
                checked={checked}
                onChange={handleCheckboxChange}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>

        {/* <Layout.AnnotatedSection title="Homepage">
            <Card sectioned title="Titles">
              <FormLayout>
                <TextStyle variation="strong">Home Text En</TextStyle>
                <TextField
                  value={valueHomeTextEn}
                  onChange={handleChangeHomeTextEn}
                />
                <TextStyle variation="strong">Home Text Ar</TextStyle>
                <TextField
                  value={valueHomeTextAr}
                  onChange={handleChangeHomeTextAr}
                />
                <TextStyle variation="strong">Home Title En</TextStyle>
                <Editor
                  apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
                  value={valueEditorHomeTitleEn}
                  init={{
                    branding: false,
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount"
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help"
                  }}
                  onChange={handleEditorChangeHomeTitleEn}
                />
                <TextStyle variation="strong">Home Title Ar</TextStyle>
                <Editor
                  apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
                  value={valueEditorHomeTitleAr}
                  init={{
                    branding: false,
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount"
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help"
                  }}
                  onChange={handleEditorChangeHomeTitleAr}
                />
                <TextStyle variation="strong">Home Subtitle En</TextStyle>
                <Editor
                  apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
                  value={valueEditorHomeSubtitleEn}
                  init={{
                    branding: false,
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount"
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help"
                  }}
                  onChange={handleEditorChangeHomeSubtitleEn}
                />
                <TextStyle variation="strong">Home Subtitle Ar</TextStyle>
                <Editor
                  apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
                  value={valueEditorHomeSubtitleAr}
                  init={{
                    branding: false,
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount"
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help"
                  }}
                  onChange={handleEditorChangeHomeSubtitleAr}
                />
              </FormLayout>
            </Card>
            <Card sectioned title="Home Button">
              <FormLayout>
                {" "}
                <TextStyle variation="strong">Button Text En</TextStyle>
                <TextField
                  value={valueButtonTextEn}
                  onChange={handleChangeButtonTextEn}
                />
                <TextStyle variation="strong">Button Text Ar</TextStyle>
                <TextField
                  value={valueButtonTextAr}
                  onChange={handleChangeButtonTextAr}
                />
                <TextStyle variation="strong">Button Link</TextStyle>
                <TextField
                  value={valueButtonLink}
                  onChange={handleChangeButtonLink}
                />
              </FormLayout>
            </Card>
            <Card sectioned title="Image">
              <FormLayout>
                <Thumbnail size="large" source={imagePreview} />
                <DropZone
                  onDrop={handleDropZoneDrop}
                  onDropAccepted={dropAccepted}
                  onDropAccepted={sendImage}
                >
                  {uploadedFiles}
                  {fileUpload}
                </DropZone>
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection> */}
        <Layout.AnnotatedSection title="Social Media">
          <Card sectioned>
            <FormLayout>
              <TextStyle variation="strong">Facebook Link</TextStyle>
              <TextField
                value={valueFacebookLink}
                onChange={handleChangeFacebookLink}
              />
              <TextStyle variation="strong">Instagram Link</TextStyle>
              <TextField
                value={valueInstagramLink}
                onChange={handleChangeInstagramLink}
              />
              <TextStyle variation="strong">Twitter Link</TextStyle>
              <TextField
                value={valueSnapchatLink}
                onChange={handleChangeSnapchatLink}
              />
              <TextStyle variation="strong">Youtube Link</TextStyle>
              <TextField
                value={valueYoutubeLink}
                onChange={handleChangeYoutubeLink}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
        <Technician type="cutting technician" />
        <Technician type="segmentation technician" />
        <Technician type="laser cutline technician" />
        <Layout.AnnotatedSection title="Contact Us">
          <Card sectioned>
            <FormLayout>
              <TextStyle type="number" variation="strong">
                Phone Number
              </TextStyle>
              <TextField
                value={valuePhoneNumber}
                onChange={handleChangePhoneNumber}
              />
              {fieldRequiredP}

              <TextStyle variation="strong">Email</TextStyle>
              <TextField value={valueEmail} onChange={handleChangeEmail} />
              {fieldRequiredE}
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
        {/* {String(Cookie.get("roleId")) !== "1" && ( */}
        {!roleId.includes(1) && (
          <Layout.AnnotatedSection title="Admin Users">
            <Card>
              <Card
                actions={[
                  {
                    content: "Show All",
                    url: "/admin/admin-users",
                  },
                ]}
              >
                <DataTable
                  columnContentTypes={["text", "text", "text", "text"]}
                  headings={[
                    <TextStyle variation="strong">ID</TextStyle>,
                    <TextStyle variation="strong">First Name</TextStyle>,
                    <TextStyle variation="strong">Last Name</TextStyle>,
                    <TextStyle variation="strong">Role</TextStyle>,
                  ]}
                  rows={usersItems}
                />
                {totalPagesAdminUsers >= 2 && (
                  <div id="pagination" style={{ marginTop: "20px" }}>
                    <Pagination
                      hasPrevious={currentPageAdminUsers > 1 && true}
                      onPrevious={() => {
                        setCurrentPageAdminUsers(currentPageAdminUsers - 1);
                      }}
                      hasNext={
                        currentPageAdminUsers < totalPagesAdminUsers && true
                      }
                      onNext={() => {
                        setCurrentPageAdminUsers(currentPageAdminUsers + 1);
                      }}
                    />
                  </div>
                )}
              </Card>
            </Card>
          </Layout.AnnotatedSection>
        )}

        <Layout.AnnotatedSection title="Currency Rate Conversion">
          <Card
            actions={[
              {
                content: "Add New",
                url: "/admin/settings/addcurrency",
              },
            ]}
          >
            <DataTable
              columnContentTypes={["text", "text", "text", "text", "text"]}
              headings={[
                <TextStyle variation="strong">Currency</TextStyle>,
                <TextStyle variation="strong">Rate</TextStyle>,
                <TextStyle variation="strong">Sign En</TextStyle>,
                <TextStyle variation="strong">Sign Ar</TextStyle>,
                <TextStyle variation="strong"></TextStyle>,
              ]}
              rows={itemsCurrencies}
            />
          </Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection title="Payfort Link Generation">
          <FormLayout>
            <TextStyle variation="strong">Amount</TextStyle>
            <TextField
              value={valuePayfortAmount}
              onChange={handleChangePayfortAmount}
              type="number"
            />

            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Select User</TextStyle>

                <Select
                  placeholder="Search user by name"
                  // multi
                  // noDataRenderer={customNoDataRenderer}
                  onChange={handleSelectUser}
                  values={[]}
                  options={optionsUsers}
                  // searchFn={(value) => searchUser(value.state.search)}
                />
                {fieldRequiredProduct}
              </FormLayout>

              <FormLayout>
                <TextStyle variation="strong">Select Product</TextStyle>
                <Select
                  options={optionsOfProductsAndPlans}
                  onChange={handleSelectProduct}
                  value={selectedProduct}
                />
              </FormLayout>
            </FormLayout.Group>
            <Button onClick={generatePayfortLink}>Generate Payfort Link</Button>

            {generatedPayfortLink && (
              <div style={{ marginTop: 20 }}>
                <FormLayout>
                  <TextStyle variation="strong">
                    The generated payfort link is :
                  </TextStyle>
                  <TextStyle>{generatedPayfortLink}</TextStyle>
                  <Button onClick={copyPayfortLink}>Copy Link</Button>
                </FormLayout>
              </div>
            )}
          </FormLayout>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection title="Backup Database">
          <Card sectioned>
            <Button primary onClick={handleDownloadBackup}>
              Download
            </Button>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSubmit,
        }}
      />
      {toastMarkup}
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function handlePageChangeAdminUsers(currentPageAdminUsers) {
    setCurrentPageAdminUsers(currentPageAdminUsers);
  }
  function handleDownloadBackup() {
    window.open("https://api.basma.com/admin/v1/backup-db");
  }
  function handleChangePayfortAmount(valuePayfortAmount) {
    setValuePayfortAmount(valuePayfortAmount);
  }
  function handleSelectUser(selectedUser) {
    setSelectedUser(selectedUser[0].value);
  }
  function handleSelectProduct(selectedProduct) {
    setSelectedProduct(selectedProduct[0].value);
  }
  function errorUploadImage() {
    setToastContent("Failed to upload");
    //toastContent="Failed to upload";
    toggleActive();
  }
  function successUploadImage() {
    setToastContent("Image Uploaded");
    // toastContent="Image Uploaded";
    toggleActive();
  }
  function handleChangePromocode(valuePromocode) {
    setValuePromocode(valuePromocode);
  }
  function handleChangeHomeTextEn(valueHomeTextEn) {
    varBoolUpload = true;
    setValueHomeTextEn(valueHomeTextEn);
  }
  function handleChangeHomeTextAr(valueHomeTextAr) {
    setValueHomeTextAr(valueHomeTextAr);
  }
  function handleChangeButtonTextEn(valueButtonTextEn) {
    setValueButtonTextEn(valueButtonTextEn);
  }
  function handleChangeButtonTextAr(valueButtonTextAr) {
    setValueButtonTextAr(valueButtonTextAr);
  }
  function handleChangeButtonLink(valueButtonText) {
    setValueButtonLink(valueButtonText);
  }

  function handleChangeFacebookLink(valueFacebookLink) {
    setValueFacebookLink(valueFacebookLink);
  }
  function handleChangeInstagramLink(valueButtonText) {
    setValueInstagramLink(valueButtonText);
  }
  function handleChangeSnapchatLink(valueSnapchatLink) {
    setValueSnapchatLink(valueSnapchatLink);
  }
  function handleChangeYoutubeLink(valueYoutubeLink) {
    setValueYoutubeLink(valueYoutubeLink);
  }

  function handleChangePhoneNumber(valueButtonText) {
    setValuePhoneNumber(valueButtonText);
  }
  function handleChangeEmail(checked) {
    setValueEmail(checked);
  }
  function handleCheckboxChange(checked) {
    setChecked(checked);
  }
  function handleCheckboxChange(checked) {
    setChecked(checked);
  }
  function handleChangeSAR(valueSAR) {
    setValueSAR(valueSAR);
  }
  function handleChangeLBP(valueLBP) {
    setValueLBP(valueLBP);
  }
  function handleChangeSwissFranc(valueSwissFranc) {
    setValueSwissFranc(valueSwissFranc);
  }
  function handleChangeEuro(valueEuro) {
    setValueEuro(valueEuro);
  }
  function handleChangeTitleEnSar(valueTitleEnSar) {
    setValueTitleEnSar(valueTitleEnSar);
  }
  function handleChangeTitleArSar(valueTitleArSar) {
    setValueTitleArSar(valueTitleArSar);
  }

  function handleChangeTitleEnUsd(valueTitleEnUsd) {
    setValueTitleEnUsd(valueTitleEnUsd);
  }
  function handleChangeTitleArUsd(valueTitleArUsd) {
    setValueTitleArUsd(valueTitleArUsd);
  }

  function handleChangeTitleEnLbp(valueTitleEnLbp) {
    setValueTitleEnLbp(valueTitleEnLbp);
  }
  function handleChangeTitleArLbp(valueTitleArLbp) {
    setValueTitleArLbp(valueTitleArLbp);
  }

  function handleChangeTitleEnSwissFranc(valueTitleEnSwissFranc) {
    setValueTitleEnSwissFranc(valueTitleEnSwissFranc);
  }
  function handleChangeTitleArSwissFranc(valueTitleArSwissFranc) {
    setValueTitleArSwissFranc(valueTitleArSwissFranc);
  }

  function handleChangeTitleEnEuro(valueTitleEnEuro) {
    setValueTitleEnEuro(valueTitleEnEuro);
  }
  function handleChangeTitleArEuro(valueTitleArEuro) {
    setValueTitleArEuro(valueTitleArEuro);
  }
  function copyPayfortLink() {
    navigator.clipboard.writeText(generatedPayfortLink);
  }
  function generatePayfortLink() {
    if (selectedProduct === "") {
      setFieldRequiredProduct(
        <InlineError message="Please select a product" fieldID="myFieldID" />
      );
    } else {
      setFieldRequiredProduct(<InlineError message="" fieldID="myFieldID" />);
      const body = {
        user_id: selectedUser,
        amount: valuePayfortAmount,
        product_id: selectedProduct.split("_")[0],
        plan_id: selectedProduct.split("_")[0],
      };
      if (selectedProduct.split("_")[1] === "plan") delete body.product_id;
      if (selectedProduct.split("_")[1] === "product") delete body.plan_id;

      axios
        .post("/admin/v1/payfort/generate-link", body, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setGeneratedPayfortLink("https://api.basma.com/" + res.data.link);
          setToastContent("Payfort Link Generated");
          toggleActive();
        })
        .catch((err) => console.log(err));
    }
  }
  function handleSubmit() {
    if (
      (valueEmail !== null &&
        valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
      (valuePhoneNumber !== null &&
        valuePhoneNumber !== "" &&
        valuePhoneNumber.length !== 8)
    ) {
      if (
        valueEmail !== null &&
        valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      )
        setFieldRequiredE(
          <InlineError message="Invalid email" fieldID="myFieldID" />
        );

      if (
        valuePhoneNumber !== null &&
        valuePhoneNumber !== "" &&
        valuePhoneNumber.length !== 8
      )
        setFieldRequiredP(
          <InlineError message="Phone length must be 8" fieldID="myFieldID" />
        );
    } else {
      const body = {
        promo_header_text: {
          en: valueEditorNotificationEn,
          ar: valueEditorNotificationAr,
        },
        bundle_popup_details: bundleTitle,
        promo_header_status: checked,

        social_media: {
          Facebook: valueFacebookLink,
          Instagram: valueInstagramLink,
          Snapchat: valueSnapchatLink,
          Youtube: valueYoutubeLink,
        },
        contact_phone: valuePhoneNumber,
        contact_email: valueEmail,
        others: { promo_code: valuePromocode },
        ...(startDateValue &&
          startTimeValue && {
            promo_header_starting_time: `${startDateValue} ${startTimeValue}`,
          }),
        ...(endDateValue &&
          endTimeValue && {
            promo_header_ending_time: `${endDateValue} ${endTimeValue}`,
          }),
        // currency_converter: currencyConverter,
      };

      axios
        .post("/admin/v1/settings", body, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setToastContent("Saved");
          toggleActive();
        })
        .catch((err) => console.log(err));
    }
  }
}
export default Settings;
