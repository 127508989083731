import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Link,
  Button,
  Page,
  AppProvider,
  Checkbox,
  Icon,
  Image,
  DisplayText,
  FormLayout,
  ChoiceList,
  Filters,
  Loading,
  Avatar,
  TextField,
  Select,
  Card,
  Layout,
  TextStyle,
  ButtonGroup,
  Badge,
  Toast,
  Pagination,
  Modal,
  TextContainer,
} from "@shopify/polaris";

import Cookie from "js-cookie";
import axios from "../../axios";
import history from "../../history";
import "@zendeskgarden/react-pagination/dist/styles.css";
import emptyIcon from "../../img/emptyList.svg";
import { useParams } from "react-router-dom";
import moment from "moment";

function Orders({userId}) {
  const { id } = useParams();
  let perPage = 5;
  const [email, setEmail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isListEmpty, setIsListEmpty] = useState(true);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasOffSessionPayment,setHasOffSessionPayment] =useState(false)
  const [hasUserOverduePaymentReminder, setHasUserOverduePaymentReminder] =
    useState(false);
  const [offSessionPaymentModal,setOffSessionPaymentModal] =useState(false)
  const [userOverduePaymentReminderModal, setUserOverduePaymentReminderModal] =
    useState(false);
  const [toastMessage, setToastMessage] = useState("Account Disactivated");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;
  const [selected, setSelected] = useState("All");
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const options = [
    { label: "All (Except Incomplete orders)", value: "All" },
    { label: "Paid", value: "Paid" },
    { label: "Failed", value: "Failed" },
    { label: "Refunded", value: "Refunded" },
    { label: "Pending", value: "Pending" },
    { label: "Canceled", value: "Canceled" },
    { label: "Incomplete", value: "Incomplete" },
  ];
  async function handleSetReceivedTracking(e){
    await axios.get(`/admin/v1/users/${id}/order/${e.currentTarget.id}/mark_as_received`, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }
  )
    window.location.reload()
  }
  async function handleSwitchOffSessionPayment(){
    axios
    .post(
      `/admin/v1/stripe/switchOff`,{'user_id':id},
      {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }
    )
    .then((result) => {
      setToastMessage("Off Session payment disabled");
      toggleActive();
      setHasOffSessionPayment(false)
      setOffSessionPaymentModal(false)
    })
    .catch((err) => console.log(err))
}

  const changeUserOverduePaymentReminderStatus = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `admin/v1/users/${id}/overdue-payments-reminder`,
        {},
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        },
      );
      if (response.status === 200) {
        const { success, message } = response.data;
        if (success) {
          setHasUserOverduePaymentReminder(!hasUserOverduePaymentReminder);
        }
        setToastMessage(message);
        toggleActive();
        closeUserOverduePaymentReminderModal();
      }
    } catch (e) {
      console.log("Error in changeUserOverduePaymentReminderStatus: ", e);
    } finally {
      setLoading(false);
    }
  };

  const closeUserOverduePaymentReminderModal = () => {
    setUserOverduePaymentReminderModal(false);
  }

  const openUserOverduePaymentReminderModal = () => {
    setUserOverduePaymentReminderModal(true);
  }

  async function handleSetDispatchedTracking(e){
    await axios.get(`/admin/v1/users/order/${e.currentTarget.id}/mark_as_dispatched`, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    });
    window.location.reload()
  }
  useEffect(() => {
   axios.get(`admin/v1/users/${id}/info`,{
    headers: {
      Authorization: "Bearer " + Cookie.get("token"),
    },
  }).then((result)=>{
    setHasOffSessionPayment(result.data.user.stripe_customer_id?true:false)
     setHasUserOverduePaymentReminder(
       result?.data?.userOverduePaymentReminderStatus,
     );
    setEmail(result.data.user.email)})
    let url = selected != "Incomplete" ?`admin/v1/users/${id}/orders?per_page=${perPage}&page=${currentPage}${
      selected != "All" ? `&filter[payment_status]=${selected}` : ""
    }` :`admin/v1/users/${id}/incomplete_orders?per_page=${perPage}&page=${currentPage}`
    axios
      .get(url
        ,
        {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      )
      .then((result) => {
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        result.data.data &&
          result.data.data.data &&
          setItems(
            result.data.data.data.map((item) => {
              let isOrderPaid = item.payment_status == 'Paid'; 
              let isMarkAsDispatchedDisabled = item.trackings.length > 0 || (item.trackings.length == 0 && item.ak_dispatched_date);
              let isMarkAsReceivedDisabled = item.tracking_status || !isMarkAsDispatchedDisabled || !isOrderPaid;
              const paidAtDate = item.paid_at ?? item.created_at;
              return [
              moment(paidAtDate)
              .add(2, "hours")
              .format("YYYY-MM-DD hh:mm:ss"),
              item.currency+" " + (item.total_price / 100).toFixed(2),
              item.user_id,
              item.transaction_id,
              item.payment_method,
              <TextStyle >
                {item.product && !item.plan_id
                  ? item.product.title.en
                  : !item.product && item.plan_id
                  ? "Aligner"
                  : item.cart_id ? 
                  <Link onClick={() => window.open(`/admin/shop/orders/${item.id}`, '_blank')}>
                    Cart
                  </Link>: "Generated Link"}
              </TextStyle>,

              <TextStyle>{item.coupon ? item.coupon.code : null}</TextStyle>,
              <TextStyle>
                {item.coupon ? item.coupon.amount + " %" : null}
              </TextStyle>,
              <Badge status="default">{item.payment_status}</Badge>,
              item.currency+" " + (item.refunded_amount / 100).toFixed(2),
              item.shipping_method && item.shipping_method,
              item.note && item.note.payment_method && item.note.payment_method,
              item.platform,
              selected !="Incomplete" &&
              item.id,
              item.tracking_status?item.tracking_status:"",
              item.payment_status == 'Paid' && !item.cart_id && <Button
                disabled={isMarkAsDispatchedDisabled || !isOrderPaid}
                id={String(item.id)}
                onClick={(e) => handleSetDispatchedTracking(e)}
                >
                Mark as Dispatched
              </Button>,
              item.payment_status == 'Paid' && !item.cart_id && <Button
                disabled={isMarkAsReceivedDisabled}
                id={String(item.id)}
                onClick={(e) => handleSetReceivedTracking(e)}
                >
                Mark as Received
              </Button>,
              item.payment_status == 'Paid' && !item.cart_id && <Button
                id={String(item.id)}
                onClick={(e) => handleAddTracking(e)}
                >
                Add Tracking
              </Button>,
            ]})
            );
          })
      .catch((err) => console.log(err));
  }, [currentPage, selected]);
  
  const overPaymentStatusTitle = hasUserOverduePaymentReminder ? "Enable Overdue Payment Reminder" : "Disable Overdue Payment Reminder";

  const emtyState = isListEmpty ? (
    <div className="message-no-result"
    >
      <img src={emptyIcon} className="empty-state-image"></img>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;
  return (
    <Layout.Section>
      <Card
        sectioned
        title={<div> <h2 className="Polaris-Heading" style={{display:"inline-block",marginRight:"10px"}}>TRANSACTIONS</h2><Badge status={hasOffSessionPayment ? "success": "critical"}>{hasOffSessionPayment ? "OffSession Payment Enabled":"OffSession Payment Disabled"}</Badge></div>}
        actions={[
          hasOffSessionPayment&& {
            content: "Disable Automatic Payment",
            onClick: () =>
             setOffSessionPaymentModal(true),
          },
          {
            content: overPaymentStatusTitle,
            onClick: openUserOverduePaymentReminderModal,
          },
          {
            content: "Refresh",
            onClick: () =>
              axios
                .get(
                  `/admin/v1/users/${id}/orders-refresh`,

                  {
                    headers: {
                      Authorization: "Bearer " + Cookie.get("token"),
                    },
                  }
                )
                .then((result) => {
                  setToastMessage("Orders section refreshed");
                  toggleActive();
                })
                .catch((err) => console.log(err)),
          },
          {
            content: "Offline Payments",
            url:'/admin/orders/manual/payments/'+email,
          },
        ]}
      >
        <Select
          label="Filter by Payment Status :"
          options={options}
          onChange={handleSelectChange}
          value={selected}
        />
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">Date</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
            <TextStyle variation="strong">Total price</TextStyle>,
            <TextStyle variation="strong">User ID</TextStyle>,
            <TextStyle variation="strong">Transaction ID</TextStyle>,
            <TextStyle variation="strong">Payment Gateway</TextStyle>,
            <TextStyle variation="strong">Product Title</TextStyle>,
            <TextStyle variation="strong">Promo Code</TextStyle>,
            <TextStyle variation="strong">Discount Percentage</TextStyle>,
            <TextStyle variation="strong">Payment status</TextStyle>,
            <TextStyle variation="strong">Refunded Amount</TextStyle>,
            <TextStyle variation="strong">Shipping method</TextStyle>,
            <TextStyle variation="strong">Payment method</TextStyle>,
            <TextStyle variation="strong">Payment Platform</TextStyle>,
            <TextStyle variation="strong">Order ID</TextStyle>,
            <TextStyle variation="strong">Received</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
        />
        {totalPages >= 2 && (
          <div
            style={{
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Pagination
              hasPrevious={currentPage > 1 && true}
              onPrevious={() => {
                setCurrentPage(currentPage - 1);
              }}
              hasNext={currentPage < totalPages && true}
              onNext={() => {
                setCurrentPage(currentPage + 1);
              }}
            />
          </div>
        )}
        {emtyState}
      </Card>
      <Modal
            open={offSessionPaymentModal}
            onClose={() => setOffSessionPaymentModal(false)}
            title="Disable Auto Payments"
            primaryAction={{
              content: "No",
              onAction: () => setOffSessionPaymentModal(false),
            }}
            secondaryActions={[
              {
                content: "Yes",
                onAction: handleSwitchOffSessionPayment,
              },
            ]}
          >
            <Modal.Section>
              <TextContainer>
                <p>Are you sure you want to Disable Auto Payments?</p>
                <p><b>This Action is irreversible</b> (At least from your side :p )</p>
              </TextContainer>
            </Modal.Section>
          </Modal>
      <Modal
        open={userOverduePaymentReminderModal}
        onClose={closeUserOverduePaymentReminderModal}
        title={overPaymentStatusTitle}
        primaryAction={{
          content: "No",
          onAction: closeUserOverduePaymentReminderModal,
        }}
        secondaryActions={[
          {
            content: "Yes",
            onAction: changeUserOverduePaymentReminderStatus,
            loading,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to {overPaymentStatusTitle}?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </Layout.Section>
  );

  function handleAddTracking(e) {
    history.push(`/admin/orders/${e.currentTarget.id}/trackings`, {userId});
  }
}
export default React.memo(Orders);
