import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import axios from "../axios";
import Cookie from "js-cookie";
import ShopLayout from '../components/ShopLayout';
import "./Shop.css"
import {
    Image,
    DisplayText,
    Card,
    TextStyle,
    Spinner,
    Page,
  } from "@shopify/polaris";
  import emptyIcon from "../img/emptyList.svg";

function ShopOrder() {

  const { id } = useParams()
  const [orderDetails, setOrderDetails] = useState({})
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOrderDetails()
  }, [])
  let currency = orderDetails?.currency === "USD" ? "$" : orderDetails?.currency+" "
  const emptyState = loading ? (
    <div className="spinnerContainer">
      <div className="vertical-center">
        <Spinner accessibilityLabel="Spinner example" size="large" />
      </div>
    </div>
  ) : (
    !orderDetails?.id &&
    <div className="message-no-result"
    >
      <div className="message-no-result-img">
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  );

  const getOrderDetails = async () => {
    const response = await axios.get(`admin/v1/shop/orders/${id}`,
        {
        headers: {
            Authorization: "Bearer " + Cookie.get("token"),
            },
        }
    )
    try{
        setOrderDetails(response.data.order)
    }catch(err){
        console.log(err)
    }finally{
        setLoading(false)
    }
  }
  const { original, discount } = orderDetails?.cart && orderDetails.cart.products
  ? orderDetails.cart.products.reduce(
      (totals, element) => {
        totals.original += element.original_amount * element.quantity;
        totals.discount += (element.original_amount - element.paid_amount) * element.quantity;
        return totals;
      },
      { original: 0, discount: 0 }
    )
  : { original: 0, discount: 0 };
  
  if(!orderDetails?.id || loading){
    return (
        <ShopLayout>
            {emptyState}
        </ShopLayout>
    )
  }
  return (
    <ShopLayout>
        <Page
            title={"#"+orderDetails?.id}
            breadcrumbs={[{ url: "/admin/shop/orders" }]}>
        <div>
            <div className='parent-container'>
                <div className='order-container'>
                    <Card>
                        <Card.Section>
                            <TextStyle variation="strong">
                                Order Details
                            </TextStyle>
                        </Card.Section>
                        <Card.Section>
                            {orderDetails?.cart?.products && orderDetails.cart.products.map((item,ind)=>{
                                let product = item.price.option.product;
                                let option = item.price.option;
                                let title  = JSON.parse(product.title)
                                return (
                                    <div key={ind}>
                                        <div className='order-item-container'>
                                            <div>
                                                <Image
                                                    source={product.first_image.file_url}
                                                    alt={title.en}
                                                    width={75}
                                                    height={75}
                                                />
                                            </div>
                                            <div div className='order-item-info'>
                                                <TextStyle variation="strong">
                                                    {title.en}
                                                    {option.value?.en && " - "+option.value.en}
                                                    {item.is_subscription ? " - Subscription": ""}
                                                </TextStyle>
                                            </div>
                                            <div div className='order-item-price-info'>
                                                {item.original_amount != item.paid_amount &&<TextStyle variation="strong">
                                                    <s>{currency}{item.original_amount}</s>
                                                </TextStyle>}
                                                <TextStyle variation="strong">
                                                    {currency}{item.paid_amount}
                                                </TextStyle>
                                                x
                                                <TextStyle variation="strong">
                                                    {item.quantity}
                                                </TextStyle>
                                            </div>
                                            <div>
                                            <TextStyle variation="strong">
                                                    {currency}{item.quantity * item.paid_amount}
                                                </TextStyle>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Card.Section>
                    </Card>
                    <Card>
                        <Card.Section>
                            <TextStyle variation="code">
                                {orderDetails.payment_status}
                            </TextStyle>
                        </Card.Section>
                        <Card.Section>
                            <div className='order-info-container'>
                                <div className='order-info'>
                                    <div className='order-info-s'><b>Subtotal</b></div>
                                    <div className='order-info-l'>{orderDetails?.cart?.products?.length} item{orderDetails?.cart?.products?.length>1 ? "s":""}</div>
                                    <div className='order-info-se'>{currency}{original}</div>
                                </div>
                                <div className='order-info'>
                                    <div className='order-info-s'><b>Discount</b></div>
                                    <div className='order-info-l'>{orderDetails.cart.coupon? orderDetails.cart.coupon.code : ""}</div>
                                    <div className='order-info-se'>{orderDetails.cart.coupon? "- "+currency + discount : ""}</div>
                                </div>
                                <div className='order-info'>
                                    <div className='order-info-s'><b>Shipping</b></div>
                                    <div className='order-info-l'>{orderDetails.cart.shipping_coupon_id ? "Free": "Standard"}</div>
                                    <div className='order-info-se'>{orderDetails.cart.shipping_coupon_id ? "": currency + orderDetails.shipping_price/100}</div>
                                </div>
                                <div className='order-info' style={{height:"auto"}}>
                                    <div className='order-info-s'><b>Total</b></div>
                                    <div className='order-info-l'></div>
                                    <div className='order-info-se'>{currency}{orderDetails.total_price/100 + orderDetails.shipping_price/100}</div>
                                </div>
                            </div>
                        </Card.Section>
                        <Card.Section>
                            <div className='order-info' style={{height:"auto"}}>
                                <div className='order-info-s'><b>Paid</b></div>
                                <div className='order-info-l'></div>
                                <div className='order-info-se'>{currency}{orderDetails.total_price/100 + orderDetails.shipping_price/100}</div>
                            </div>
                        </Card.Section>
                    </Card>

                </div>
                <div className='user-container'>
                    <Card>
                        <Card.Section>
                            <TextStyle variation="strong">
                                Notes
                            </TextStyle>
                        </Card.Section>
                        <Card.Section>
                            Cart Note: {orderDetails.note.cart_note}
                            <br/>
                            {orderDetails.note.parent_id? 
                            "User sent order with batch "+ orderDetails.note.parent_id: ""}
                        </Card.Section>
                    </Card>
                    <Card>
                        <Card.Section>
                            <TextStyle variation="strong">
                                Customer
                            </TextStyle>
                        </Card.Section>
                        <Card.Section>
                        <p>
                                {orderDetails.user.full_name}
                            </p>
                            <TextStyle variation="strong">
                                Contact Information
                            </TextStyle>
                            <p>
                                {orderDetails.user.email}
                            </p>
                        </Card.Section>
                        <Card.Section>
                            <TextStyle variation="strong">
                                Shipping Address
                            </TextStyle>
                            <p>
                                <br></br>
                                {orderDetails.shipping_address.address}
                                <br></br>
                                {orderDetails.shipping_address.address_2 ? (<>{orderDetails.shipping_address.address_2}<br/></>): ""}
                                {orderDetails.shipping_address.address_3 ? (<>{orderDetails.shipping_address.address_3}<br/></>): ""}
                                {orderDetails.shipping_address.city} 
                                <br></br>
                                {orderDetails.shipping_address.country}
                                <br></br>
                                {orderDetails.shipping_address.phone}
                            </p>
                        </Card.Section>
                    </Card>
                </div>
            </div>
        
        </div>
        </Page>
     </ShopLayout>
  )
}

export default ShopOrder
