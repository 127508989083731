import React, { useCallback, useContext, useState } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { Button } from "@shopify/polaris";
import { ShopContext } from "../ShopContext";
import history from "../history";
import Select from "react-select";

export function ShopProductOption({index, product_id, id = null, name = {'en': '', 'ar':''}, value = {'en': '', 'ar':''}, editable, width = 20, style, archOption = 'both'}) {
  const [optionName, setOptionName] = useState({
    en: name?.en,
    ar: name?.ar,
  });
  const [optionValue, setOptionValue] = useState({
    en: value?.en,
    ar: value?.ar,
  });
  const[edit, setEdit] = useState(editable);
  const options = [
    { value: 'both', label: 'Both' },
    { value: 'upper', label: 'Upper' },
    { value: 'lower', label: 'Lower' },
  ]
  const [arches, setArches] = useState(options.find((option) => option.value === archOption));

  // const options = [
  //   'Both' }
  //   'Upper' ,
  //   'Lower' ,
  // ]
  const handleSelectArches = useCallback(
    (selectedOption) => setArches(selectedOption),
    []
);
  const {
    states: {
      shopData,
    }, actions: {
      storeProductOption,
    },
  } = useContext(ShopContext);

  const save = () => {
    const bodyObj = {
      id: id,
      product_id: product_id,
      name: optionName,
      value: optionValue,
      arches: arches.value,
    }
    storeProductOption(index, bodyObj);
    setEdit((prev) => !prev);
  }
  const handleAddPrice = () => {
    history.push(`/admin/shop/product/${product_id}/prices/${id}`);
  }
  const handleChangeName = (key, value) => {
    setOptionName((prev) => ({ ...prev, [key]: value }));
  };
  const handleChangeValue = (key, value) => {
    setOptionValue((prev) => ({ ...prev, [key]: value }));
  };

  return(
    <tr style={{display: "flex", padding: "1em 0", gap: "20px"}}>
      <td className="plan-column" style={style}>
        <TextField disabled={!edit} type="text" className="plan-field" value={optionName.en} onChange={(e) => handleChangeName("en", e.target.value)}/>
      </td>
      <td className="plan-column" style={style}>
        <TextField disabled={!edit} type="text" className="plan-field" value={optionName.ar} onChange={(e) => handleChangeName("ar", e.target.value)}/>
      </td>
      <td className="plan-column" style={style}>
        <TextField disabled={!edit} type="text" className="plan-field" value={optionValue.en} onChange={(e) => handleChangeValue("en", e.target.value)}/>
      </td>
      <td className="plan-column" style={style}>
        <TextField disabled={!edit} type="text" className="plan-field" value={optionValue.ar} onChange={(e) => handleChangeValue("ar", e.target.value)}/>
      </td>
      <td className="plan-column" style={style}>
        <Select
            menuPosition="fixed"
            menuPlacement="auto"
            name="arches"
            options={options}
            onChange={handleSelectArches}
            value={arches}
            isDisabled={!edit}
          />
      </td>
      <td className="plan-button" style={style}>
        <Button disabled={shopData.newOptionLoading.find(loading => loading) ? true : false} loading={shopData.newOptionLoading.length ? shopData.newOptionLoading[index] : false} style={{backgroundColor: "rgba(0, 128, 96, 1)", padding: "1rem 3rem", cursor: "pointer"}} textAlign="bottom" primary onClick={() => {edit ? save() : setEdit((prev)=>(!prev))}}>{edit ? 'Save' : 'Edit'}</Button>
      </td>
      {id ? 
        <td className="plan-button" style={style}>
          <Button destructive disabled={shopData.newOptionLoading.find(loading => loading) ? true : false} loading={shopData.newOptionLoading.length ? shopData.newOptionLoading[index] : false} style={{adding: "1rem 3rem", cursor: "pointer"}} textAlign="bottom" primary onClick={handleAddPrice}>Add Prices</Button>
        </td>
        : null
      }
    </tr>
  )
}

export default ShopProductOption