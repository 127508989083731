import { createContext, useState } from "react";
import axios from "../axios";
import Cookies from "js-cookie";
import history from "./../history";

export const BundlesContext = createContext();

export const BundlesProvider = ({ children }) => {
  const [bundleInfo, setBundleInfo] = useState({
    statues: [],
    tags: [],
    procedureTypes: [],
    shopProducts: [],
    itemsPositions: [],
    itemTypes: [],
    procedureTypePrices: [],
    loading: false,
  });

  const [bundles, setBundles] = useState({
    loading: false,
    items: [],
  });

  const [bundle, setBundle] = useState({
    loading: false,
    data: {
      title: {
        en: "",
        ar: "",
      },
      description: {
        en: "",
        ar: "",
      },
      status: "",
      tag: "none",
      position: "0",
      bundleItems: [],
      main_image: "",
      item_image: "",
    },
  });

  const handleChange = (field, value, language = null) => {
    setBundle((prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        [field]: language
          ? {
              ...prev?.data?.[field],
              [language]: value,
            }
          : value,
      },
    }));
  };

  const getBundleInfo = async () => {
    try {
      setBundleInfo((prev) => ({ ...prev, loading: true }));
      const response = await axios.get("/admin/v1/bundles/info", {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      if (response.status === 200) {
        const { data } = response.data;
        setBundleInfo((prev) => ({
          ...prev,
          statues: data.statuses,
          tags: data.tags,
          procedureTypes: data.procedure_types,
          shopProducts: data.shop_products,
          itemsPositions: data.item_positions,
          itemTypes: data.item_types,
          procedureTypePrices: data.procedure_type_prices,
        }));
      }
    } catch (error) {
      console.log("Errors in getBundleInfo", error);
    } finally {
      setBundleInfo((prev) => ({ ...prev, loading: false }));
    }
  };

  const getBundle = async (id) => {
    try {
      setBundle((prev) => ({ ...prev, loading: true }));
      const response = await axios.get(`/admin/v1/bundles/${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      if (response.status === 200) {
        let { data } = response.data;
        setBundle((prev) => ({ ...prev, data }));
      }
    } catch (error) {
      console.log("Errors in getBundle", error);
    } finally {
      setBundle((prev) => ({ ...prev, loading: false }));
    }
  };

  const postBundle = async (data) => {
    try {
      setBundleInfo((prev) => ({ ...prev, loading: true }));
      const response = await axios.post("/admin/v1/bundles", data, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      if (response.status === 200) {
        history.push("/admin/bundles");
      }
    } catch (error) {
      console.log("Errors in postBundle", error);
    } finally {
      setBundleInfo((prev) => ({ ...prev, loading: false }));
    }
  };

  const updateBundle = async (id, data) => {
    try {
      setBundle((prev) => ({ ...prev, loading: true }));
      const response = await axios.put(`/admin/v1/bundles/${id}`, data, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      if (response.status === 200) {
        history.push("/admin/bundles");
      }
    } catch (error) {
      console.log("Errors in postBundle", error);
    } finally {
      setBundle((prev) => ({ ...prev, loading: false }));
    }
  };

  const getBundles = async () => {
    try {
      setBundles((prev) => ({ ...prev, loading: true }));
      const response = await axios.get("/admin/v1/bundles", {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      if (response.status === 200) {
        const { data } = response.data;
        setBundles((prev) => ({ ...prev, items: data }));
      }
    } catch (error) {
      console.log("Errors in getBundles", error);
    } finally {
      setBundles((prev) => ({ ...prev, loading: false }));
    }
  };

  const value = {
    states: { bundleInfo, bundles, bundle },
    actions: {
      getBundles,
      getBundleInfo,
      postBundle,
      getBundle,
      updateBundle,
      handleChange,
    },
  };
  return (
    <BundlesContext.Provider value={value}>{children}</BundlesContext.Provider>
  );
};
