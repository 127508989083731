import React, { useCallback, useState, useEffect } from "react";
import {
  Thumbnail,
  Caption,
  InlineError,
  DropZone,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Select,
  Checkbox,
  Modal,
} from "@shopify/polaris";
import axios from "../axios";
import Cookie from "js-cookie";
import history from "../history";

import { useParams } from "react-router-dom";

function Edit() {
  let { id } = useParams();
  const [valueFirstName, setvalueFirstName] = useState("");
  const [valueLastName, setvalueLastName] = useState("");
  const [valueAddress, setValueAddress] = useState("");
  const [valueCountry, setvalueCountry] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const [formValue, setFormValue] = useState({
    clinic_name: "",
    city: "",
    phone_number: "",
    clinic_chair_capacity: "",
    clinic_have_scanner: "",
    clinic_have_xray: "",
    offering_teeth_straightening: "",
  })

  const handleFormChange = useCallback((value, id) => {
    setFormValue({ ...formValue, [id]: value });
  }, [formValue]);

  // const [options, setOptions] = useState([]);
  //let arrayChoices = ["Editor", "Writer", "Moderator", "Admin", "Super-Admin"];
  const booleanOptions = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ];
  const options = [
    { label: "Editor", value: "1" },
    { label: "Lab", value: "3" },
    { label: "Admin", value: "4" },
    { label: "Accounting", value: "6" },
    { label: "Doctor", value: "7" },
  ];
  const [fieldRequiredFN, setFieldRequiredFN] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredLN, setFieldRequiredLN] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredE, setFieldRequiredE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredAddress, setFieldRequiredAddress] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );

  useEffect(() => {
    axios
      .get(`/admin/v1/management/doctors/${id}/show`, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((result) => {
        setvalueFirstName(result.data.doctor.first_name);
        setvalueLastName(result.data.doctor.last_name);
        setValueAddress(result.data.doctor.doctor_profile?.address);
        setValueEmail(result.data.doctor.email);
        setFormValue({...result.data.potential_doctor,
            clinic_have_xray: String(result.data.potential_doctor.clinic_have_xray),
            clinic_have_scanner: String(result.data.potential_doctor.clinic_have_scanner),
            clinic_chair_capacity: String(result.data.potential_doctor.clinic_chair_capacity),
            offering_teeth_straightening: String(result.data.potential_doctor.offering_teeth_straightening)
          })
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Add Doctor"
      breadcrumbs={[{ content: "List Of Doctors", url: "/admin/doctors" }]}
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">First Name</TextStyle>
              <TextField
                value={valueFirstName}
                onChange={handleChangeFirstName}
              />
              {fieldRequiredFN}
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Last Name</TextStyle>
              <TextField
                value={valueLastName}
                onChange={handleChangeLastName}
              />
              {fieldRequiredLN}
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Email</TextStyle>
              <TextField value={valueEmail} onChange={handleChangeEmail} />
              {fieldRequiredE}{" "}
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Address</TextStyle>
              <TextField value={valueAddress} onChange={handleChangeAddress} />
              {fieldRequiredAddress}
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Country</TextStyle>
              <TextField value={valueCountry} onChange={handleChangeCountry} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Phone</TextStyle>
              <TextField
                value={formValue.phone_number}
                onChange={(e)=>{handleFormChange(e, "phone_number")}}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Clinic Name</TextStyle>
              <TextField
                value={formValue.clinic_name}
                onChange={(e)=>{handleFormChange(e, "clinic_name")}}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">City</TextStyle>
              <TextField
                value={formValue.city}
                onChange={(e)=>{handleFormChange(e, "city")}}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Clinic chair capacity</TextStyle>
              <TextField
                value={formValue.clinic_chair_capacity}
                onChange={(e)=>{handleFormChange(e, "clinic_chair_capacity")}}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">
                Does the clinic have a 3D intraoral scanner?
              </TextStyle>
              <Select
                options={booleanOptions}
                onChange={(e)=>{handleFormChange(e, "clinic_have_scanner")}}
                value={formValue.clinic_have_scanner}
                placeholder="Please Select"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">
                Does the clinic have a panoramic XRay?
              </TextStyle>
              <Select
                options={booleanOptions}
                onChange={(e)=>{handleFormChange(e, "clinic_have_xray")}}
                value={formValue.clinic_have_xray}
                placeholder="Please Select"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">
                Does the clinic currently offer teeth straightening options?
              </TextStyle>
              <Select
                options={booleanOptions}
                onChange={(e)=>{handleFormChange(e, "offering_teeth_straightening")}}
                value={formValue.offering_teeth_straightening}
                placeholder="Please Select"
              />
            </FormLayout>
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            onClick: () =>
              axios
                .delete("admin/v1/management/doctors/" + id + "/delete", {
                  headers: {
                    Authorization: "Bearer " + Cookie.get("token"),
                  },
                })
                .then((result) => {
                  history.push(`/admin/doctors`);
                })
                .catch((err) => console.log(err)),
          },
        ]}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function handleChangeAddress(valueAddress) {
    setValueAddress(valueAddress);
  }
  function handleChangeCountry(valueCountry) {
    setvalueCountry(valueCountry);
  }
  function handleChangeFirstName(valueFirstName) {
    setvalueFirstName(valueFirstName);
    if (valueFirstName !== "") {
      setFieldRequiredFN(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeLastName(valueLastName) {
    setvalueLastName(valueLastName);
    if (valueLastName !== "") {
      setFieldRequiredLN(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeEmail(valueEmail) {
    setValueEmail(valueEmail);
    if (valueEmail !== "") {
      setFieldRequiredE(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleSave() {
    if (
      valueFirstName === "" ||
      valueLastName === "" ||
      valueEmail === "" ||
      valueAddress === "" ||
      // valuePassword === "" ||
      // valueConfirmPassword === "" ||
      (valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
      //  ||valuePassword.length < 6 ||
      // valuePassword !== valueConfirmPassword
    ) {
      if (valueFirstName === "")
        setFieldRequiredFN(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueLastName === "")
        setFieldRequiredLN(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueEmail === "")
        setFieldRequiredE(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (valueAddress === "")
        setFieldRequiredAddress(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      if (
        valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      )
        setFieldRequiredE(
          <InlineError
            message="Please enter a valid email address"
            fieldID="myFieldID"
          />
        );
    } else {
      setPopupActive(true);
      console.log(formValue);
      const bodyObj = {
        first_name: valueFirstName,
        last_name: valueLastName,
        email: valueEmail,
        // address: valueAddress,
        // country: valueCountry,
        potential_doctor:{
          ...formValue,
          first_name: valueFirstName,
          last_name: valueLastName,
          email: valueEmail,
          clinic_have_scanner: formValue.clinic_have_scanner === "true" ? 1 : 0,
          clinic_have_xray: formValue.clinic_have_xray === "true" ? 1 : 0,
          offering_teeth_straightening: formValue.offering_teeth_straightening === "true" ? 1 : 0,
          clinic_chair_capacity: parseInt(formValue.clinic_chair_capacity)
        }

      };
      axios
        .post("admin/v1/management/doctors/" + id + "/update", bodyObj, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setPopupActive(false);
          history.push("/admin/doctors");

          //  history.push('/admin/faqsupport/viewCategory')
        })
        .catch((err) => setPopupActive(false));
    }
  }
  function handleError(err) {
    setFieldRequiredE(
      <InlineError message="This email is already used" fieldID="myFieldID" />
    );
  }
}
export default Edit;
