import React, { useState, useEffect, useContext } from "react";
import {
  TextStyle,
  Page,
  FormLayout,
  Card,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { ShopContext } from "../ShopContext";
import ShopProductCurrencies from "./ShopProductCurrencies";

function ShopProductPrice() {
  let { id, optionId } = useParams();
  const {
    states: {
      shop,
    }, actions: {
      getShopOptionPrices,
    },
  } = useContext(ShopContext);
  const [productContent, setProductContent] = useState([]);

  useEffect(() => {
    if(!shop.prices){
      return
    }
    setProductContent(shop.prices);
  }, [shop.prices]);
  const addNewPrice = () => {
    setProductContent(prev => ([
        ...prev,
        {}
      ]
    ));
  }


  useEffect(() => {
    getShopOptionPrices(id, optionId);
  }, []);


  return (
    <Page
      title={'Add Prices'}
      breadcrumbs={[{ content: "List Of Prices", url: `/admin/shop/product/${id}/edit` }]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Product: {shop.product?.title?.en}</TextStyle>
          {
            shop.option.value?
            <TextStyle variation="strong">Option: {shop.option.value.en} ({shop.option.name.en})</TextStyle>
            : null
          }
          {
            id &&
            <ShopProductCurrencies
              option_id={optionId}
              items={productContent}
              addNewPrice={addNewPrice}
              isEmpty={!productContent.length}
            />
          }
        </FormLayout>
      </Card>
    </Page>
  );
}

export default ShopProductPrice;
