import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../axios";
import ProcedureHeader from "./ProcedureHeader";
import Cookies from "js-cookie";
import Save from "./Save";
import AlertDeleteDialog from "./Popup/alert-delete-dialog.component";
import { adminActions, caseRecordTypes, IssueTypes, ReviewStatus } from "../util/constants";
import PostIprPhotoData from "./PostIprPhotoData";
import DisapprovalModal from "./DisapprovalModal";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: '2rem',
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    },
    uploadBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    float: {
      float: 'right',
    },
    input: {
      padding: '0.5rem 1.2rem',
      border: '1px lightgray solid',
      borderRadius: '5px',
      margin: '15px 0',
    },
    mediaWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '2rem',
      marginBottom: '1rem',
    },
  };
});

const AppointmentPostIprPhotos = ({
  handleTabsExpand,
  appointmentId,
  appointmentNumber,
  openCloseTabs,
  index,
  appointmentType,
  userId,
  scrollToExpandedTab,
  updateReviewStatus,
  delayedActionJob,
}) => {
  const classes = useStyles();
  const [iprData, setIprData] = useState(null);
  const [hasDelayedAction, setHasDelayedAction] = useState(false);
  const [postIprPhotosIcon, setPostIprPhotosIcon] = useState(null);
  const [success, setSuccess] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [errorFilesUpload, setErrorFilesUpload] = useState('');
  const [imageKey, setImageKey] = useState(null);
  const [fileImages, setFileImages] = useState([]);
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const [imageKeys, setImageKeys] = useState([]);
  const [reviewData, setReviewData] = useState({
    id: null,
    comment: '',
    commentError: '',
    loading: false,
    modalOpened: false,
    status: '',
  });
  const [issues, setIssues] = useState([]);
  const [options, setOptions] = useState([]);
  const [groupTeeth, setGroupTeeth] = useState(Array(1).fill([
    { num: 18, attachment: false },
    { num: 17, attachment: false },
    { num: 16, attachment: false },
    { num: 15, attachment: false },
    { num: 14, attachment: false },
    { num: 13, attachment: false },
    { num: 12, attachment: false },
    { num: 11, attachment: false },
    { num: 21, attachment: false },
    { num: 22, attachment: false },
    { num: 23, attachment: false },
    { num: 24, attachment: false },
    { num: 25, attachment: false },
    { num: 26, attachment: false },
    { num: 27, attachment: false },
    { num: 28, attachment: false },
    { num: 48, attachment: false },
    { num: 47, attachment: false },
    { num: 46, attachment: false },
    { num: 45, attachment: false },
    { num: 44, attachment: false },
    { num: 43, attachment: false },
    { num: 42, attachment: false },
    { num: 41, attachment: false },
    { num: 31, attachment: false },
    { num: 32, attachment: false },
    { num: 33, attachment: false },
    { num: 34, attachment: false },
    { num: 35, attachment: false },
    { num: 36, attachment: false },
    { num: 37, attachment: false },
    { num: 38, attachment: false },
  ]));
  const [sheets, setSheets] = useState([
    {
      notes: '',
    },
  ]);

  const handleDataFetched = () => {
    setPopupActive(true);
    axios.get(`admin/v2/users/case-record-post-ipr/${appointmentId}/show?procedure=${appointmentType?.procedure}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }).then(res => {
      if (res.data.success) {
        setHasDelayedAction(res.data.has_delayed_action);
        setIprData(res.data.data);
        setPostIprPhotosIcon(res.data.isAllProvided);
      } else {
        setPostIprPhotosIcon(0);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setPopupActive(false);
    })
  };

  const handleChangeComment = (newValue) => {
    setReviewData(prev => ({
      ...prev,
      comment: newValue,
    }));
  }

  const handleChangeModal = () => {
    setReviewData(prev => ({
      ...prev,
      modalOpened: false,
    }));
    setIssues([]);
  }

  const handleDelete = () => {
    if(photoId) {
      axios.delete(`admin/v2/case-record-post-ipr/delete/${photoId}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }).then(() => {
        handleDataFetched();
      }).catch((err) => {
        setErrorFilesUpload("Delete failed. Please try again.");
        console.log(err);
      });
    }
  }

  const handleSave = async (imageData = null) => {
    setErrorFilesUpload('');
    let body = {
      id: imageData?.id ? imageData.id : imageKey?.id,
      procedure: appointmentType.procedure,
      user_scan_info_id: appointmentId,
    };
    if(imageData?.image) {
      body = {
        ...body,
        image: imageData.image,
      };
    }
    if(imageKey?.note) {
      body = {
        ...body,
        note: imageKey.note,
      };
    }
    try {
      const response = await axios.post(`/admin/v2/users/${userId}/case-record-post-ipr`, body, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        handleDataFetched();
      }
    } catch (error) {
      setSuccess(false);
    }
  };

  const handleReviewStatus = async (status, id = null) => {
    if(!id && !reviewData?.id) {
      return;
    }
    if (status != ReviewStatus.approved && !issues.length && !reviewData.comment) {
      setReviewData(prev => ({
        ...prev,
        commentError: 'Please enter a comment',
      }));
    } else {
      let data = {
        review_status: status,
        appointment_id: appointmentId,
        procedure: appointmentType?.procedure,
        record_id: id ? id : reviewData?.id,
        record_type: caseRecordTypes.UserCaseRecordPostIprPhoto,
      };
      if(status != ReviewStatus.approved){
        let comment = `
          Appointment ${appointmentNumber} - ${appointmentType?.procedure}<br/>
          Status: Photos ${status}
        `;
        if (options.length && !issues.length) {
          return;
        }
        if(issues.length) {
          comment += '<br/>IPR issues: ';
          comment += issues.map(issue => issue.label).join(', ');
        }
        let teeth = groupTeeth[0].filter(tooth => tooth.attachment);
        if(teeth.length) {
          comment += `<br/>Affected teeth: ${teeth.map(tooth => tooth.num).join(', ')}`;
        }
        if(sheets[0].notes) {
          comment += `<br/>Affected teeth notes: ${sheets[0].notes}`;
        }
        if(reviewData.comment) {
          comment += `<br/>Notes: ${reviewData.comment}`;
        }
        data = {
          ...data,
          comment,
          images: imageKeys,
          issues: issues.map(issue => issue.id),
        };
      }
      let body = {
        action: adminActions.caseRecordsStatus,
        data,
      }
      await updateReviewStatus(body, 'Status Updated', `${caseRecordTypes.UserCaseRecordPostIprPhoto}-${iprData?.id}`);
      setReviewData(prev => ({
        ...prev,
        loading: false,
        modalOpened: false,
      }));
    }
  };

  useEffect(() => {
    handleDataFetched();
  }, []);

  useEffect(() => {
    if(delayedActionJob.refetchedRecord === `${caseRecordTypes.UserCaseRecordPostIprPhoto}-${iprData?.id}`) {
      handleDataFetched();
    }
  }, [delayedActionJob]);

  useEffect(() => {
    scrollToExpandedTab(index, 'photos');
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type='photos'
      headerTitle={<p><span className="procedure-header-title">Photos:</span> <span className='header-title-gray'>{appointmentType?.procedure}</span></p>}
      index={index}
      iconCheck={postIprPhotosIcon}
    >
      {openCloseTabs[index]?.photos && (
        <Box className={`${classes.wrapper} photos-${index}`}>
          {popupActive ?
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color='primary' />
            </div> :
            <>
              <PostIprPhotoData
                data={iprData}
                classes={classes}
                setPhotoId={setPhotoId}
                handleReviewStatus={handleReviewStatus}
                setReviewData={setReviewData}
                setErrorFilesUpload={setErrorFilesUpload}
                handleSave={handleSave}
                setOpen={setOpen}
                delayedActionJob={delayedActionJob}
                hasDelayedAction={hasDelayedAction}
                postIprPhotosIcon={postIprPhotosIcon}
                imageKey={imageKey}
                setImageKey={setImageKey}
                appointmentType={appointmentType}
                parentId={iprData?.id}
              />
              <Save uploadedBy={''} handleSave={handleSave} success={success} errorMessage={errorFilesUpload} />
            </>
          }
        </Box>
      )}
      {reviewData.modalOpened && (
        <DisapprovalModal
          activeModal={reviewData.modalOpened}
          handleChangeModal={handleChangeModal}
          title={reviewData.status === ReviewStatus.approvedWithIssues ? 'Approve With Issues' : 'Disapprove Post IPR Photos'}
          dropDownTitle='IPR Issues'
          handleSubmitDisapprove={() => handleReviewStatus(reviewData.status)}
          loading={reviewData.loading}
          commentValue={reviewData.comment}
          handleChangeComment={handleChangeComment}
          commentError={reviewData.commentError}
          setFilesImage={setFileImages}
          filesImage={fileImages}
          images={images}
          setImages={setImages}
          imagesKey={imageKeys}
          setImageKey={setImageKeys}
          issueType={IssueTypes.iprNotApproved}
          setIssues={setIssues}
          issues={issues}
          showIPRDiagram
          sheets={sheets}
          setSheets={setSheets}
          groupTeeth={groupTeeth}
          setGroupTeeth={setGroupTeeth}
          options={options}
          setOptions={setOptions}
        />
      )}
      <AlertDeleteDialog open={open} setOpen={setOpen} handleAction={handleDelete} />
    </ProcedureHeader>
  );
};

export default AppointmentPostIprPhotos;
  