import React from "react";
import {
    Image,
    DisplayText,
    Card,
} from "@shopify/polaris";
import { Button as ButtonMui, Typography } from "@material-ui/core";
import emptyIcon from "../img/emptyList.svg";
import { CardActions } from "@mui/material";
import ShopProductOption from "./ShopProductOption";

function ShopProductOptions({ product_id, items, addNewOption, isEmpty }) {

    let width = 12;
    let numberOfCCols = 7;
    let newWidth = 100 / numberOfCCols;
    if(newWidth < width){
        width = newWidth;
    }
    let headStyle = {
        width: width + "%",
        maxWidth: width + "%",
        minWidth: "initial",
    }
    return (
        <Card sectioned title="Product Options">
            <CardActions style={{ float: "right" }}>
                <ButtonMui variant="contained" style={{ backgroundColor: "rgb(0 128 96)", color: "white", padding: "1.5em 2em" }}
                    onClick={addNewOption}>
                    <Typography variant="h6" >Add New</Typography>
                </ButtonMui>
            </CardActions>
            <table style={{ display: "grid", width: "100%", overflowX: "auto", overflowY: "hidden", padding: "1em 0" }}>
                <thead>
                    <tr style={{ display: "flex", gap: '20px' }}>
                        <th className="plan-column" style={headStyle}>Name En</th>
                        <th className="plan-column" style={headStyle}>Name Ar</th>
                        <th className="plan-column" style={headStyle}>Value En</th>
                        <th className="plan-column" style={headStyle}>Value Ar</th>
                        <th className="plan-column" style={headStyle}>Arches</th>
                        <th className="plan-column" style={headStyle}></th>
                        <th className="plan-column" style={headStyle}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((item, key) => (
                            <ShopProductOption
                                style={headStyle}
                                index={key}
                                key={key}
                                product_id={product_id}
                                id={item.id}
                                name={item.name}
                                value={item.value}
                                editable={item.editable}
                                archOption={item.arches}
                            />
                        ))
                    }
                </tbody>
            </table>
            {isEmpty ? (
                <div className="message-no-result">
                    <div className="message-no-result-img">
                        <Image src={emptyIcon}></Image>{" "}
                    </div>
                    <DisplayText size="small">No results found</DisplayText>
                    <br />
                </div>
            ) : null}
        </Card>
    )
}

export default ShopProductOptions