import {
  Card,
  DropZone,
  FormLayout,
  InlineError,
  Modal,
  Page,
  Stack,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Select from "react-select";
import BundleItem from "./bundle-item.component";
import { BundlesContext } from "../context/bundles.context";
import { capitalizeWords } from "../util/helpers";
import axios from "../axios";
import Cookie from "js-cookie";
import { NoteMinor } from "@shopify/polaris-icons";

function Bundle() {
  const { id } = useParams();
  const {
    states: { bundleInfo, bundle },
    actions: {
      getBundleInfo,
      postBundle,
      getBundle,
      updateBundle,
      handleChange,
    },
  } = useContext(BundlesContext);
  const { data: bundleContent, loading: bundleLoading } = bundle;
  const { bundleItems } = bundleContent;

  const urlPlaceholder =
    "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const [mainImagePreview, setMainImagePreview] = useState("");
  const [mainImageKey, setMainImageKey] = useState("");
  const [mainImageFiles, setMainImagesFiles] = useState([]);
  const mainFileUpload = !mainImageFiles.length && <DropZone.FileUpload />;

  const [itemImagePreview, setItemImagePreview] = useState("");
  const [itemImageKey, setItemImageKey] = useState("");
  const [itemImageFiles, setItemImagesFiles] = useState([]);
  const itemFileUpload = !mainImageFiles.length && <DropZone.FileUpload />;

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setMainImagesFiles((files) => [...files, ...acceptedFiles]),
    [],
  );

  const uploadedMainFiles = mainImageFiles.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={mainImageFiles[mainImageFiles.length - 1].name}
        source={
          validImageTypes.indexOf(
            mainImageFiles[mainImageFiles.length - 1].type,
          ) > 0
            ? window.URL.createObjectURL(
                mainImageFiles[mainImageFiles.length - 1],
              )
            : NoteMinor
        }
      />
      <div>{mainImageFiles[mainImageFiles.length - 1].name} </div>
    </Stack>
  );

  const handleDropZoneDrop2 = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setItemImagesFiles((files) => [...files, ...acceptedFiles]),
    [],
  );

  const uploadedItemFiles = itemImageFiles.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={itemImageFiles[itemImageFiles.length - 1].name}
        source={
          validImageTypes.indexOf(
            itemImageFiles[itemImageFiles.length - 1].type,
          ) > 0
            ? window.URL.createObjectURL(
                itemImageFiles[itemImageFiles.length - 1],
              )
            : NoteMinor
        }
      />
      <div>{itemImageFiles[itemImageFiles.length - 1].name} </div>
    </Stack>
  );

  const [popupActive, setPopupActive] = useState(false);
  const [fieldRequired, setFieldRequired] = useState({
    title: {
      en: <InlineError message="" fieldID="myFieldID" />,
      ar: <InlineError message="" fieldID="myFieldID" />,
    },
    description: {
      en: <InlineError message="" fieldID="myFieldID" />,
      ar: <InlineError message="" fieldID="myFieldID" />,
    },
  });

  const {
    loading,
    statues,
    tags,
    procedureTypes,
    shopProducts,
    itemsPositions,
    itemTypes,
    procedureTypePrices,
  } = bundleInfo;
  const statuesOptions = Array.isArray(statues)
    ? statues.map((status) => ({
        value: status,
        label: capitalizeWords(status),
      }))
    : [];

  const tagsOptions = Array.isArray(tags)
    ? tags.map((tag) => ({
        value: tag,
        label: capitalizeWords(tag),
      }))
    : [];

  const shopProductsOptions = Array.isArray(shopProducts)
    ? shopProducts.map((shopProduct) => ({
        value: shopProduct.id,
        label: capitalizeWords(JSON.parse(shopProduct.title).en),
      }))
    : [];

  const procedureTypesOptions = Array.isArray(procedureTypes)
    ? procedureTypes.map((procedureType) => ({
        value: procedureType.id,
        label: capitalizeWords(procedureType.name),
      }))
    : [];

  const itemsPositionsOptions = Array.isArray(itemsPositions)
    ? itemsPositions.map((position) => ({
        value: position,
        label: capitalizeWords(position),
      }))
    : [];

  const typeOptions = Array.isArray(itemTypes)
    ? itemTypes.map((itemType) => ({
        value: itemType,
        label: capitalizeWords(itemType).split("_").join(" "),
      }))
    : [];

  const shopProductPrices = Array.isArray(shopProducts) ? shopProducts : [];
  const procedurePrices = procedureTypePrices ?? null;

  const addBundleItem = () => {
    const newBundleItems = [
      ...bundleItems,
      { type: "", itemType: "", quantity: 0, position: 0, prices: [] },
    ];
    handleChange("bundleItems", newBundleItems);
  };

  const removeBundleItem = (index) => {
    const newBundleItems = bundleItems.filter((_, i) => i !== index);
    handleChange("bundleItems", newBundleItems);
  };

  const getBundleItemPrices = (type, value) => {
    if (type != "shop_product") {
      return procedurePrices.filter(
        (procedureType) => procedureType?.procedure_type_id == value,
      );
    }
  };

  const handleRemovePrice = (index, item, priceIndex) => {
    const updatedPrices = item.prices.filter((_, i) => i !== priceIndex);
    handleBundleItemChange(index, "prices", updatedPrices);
  };

  const handleChangePrice = (index, item, priceIndex, field, value) => {
    const updatedPrices = item.prices.map((price, i) =>
      i === priceIndex ? { ...price, [field]: value } : price,
    );
    handleBundleItemChange(index, "prices", updatedPrices);
  };

  const handleBundleItemChange = (index, field, value) => {
    const newBundleItems = [...bundleItems];
    if (field === "type") {
      newBundleItems[index] = {
        type: value,
        itemType: "",
        quantity: "0",
        position: "",
        prices: [],
      };
    } else if (field === "itemType") {
      const prices = getBundleItemPrices(newBundleItems[index].type, value);
      newBundleItems[index] = {
        ...newBundleItems[index],
        itemType: value,
        quantity: "0",
        position: "",
      };
      if (newBundleItems[index].type === "procedure") {
        newBundleItems[index].prices = prices;
      }
    } else {
      newBundleItems[index][field] = value;
    }
    handleChange("bundleItems", newBundleItems);
  };

  const sendMainImage = useCallback(
    (files) => {
      setPopupActive(true);
      setMainImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : urlPlaceholder,
      );
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", files[files.length - 1].type);
      form_data.append("file_name", files[files.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setMainImageKey(res.data.key);
          url2 = res.data.url;
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": files[files.length - 1].type,
              },
            })
            .then(() => {
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [mainImageFiles],
  );

  const sendItemImage = useCallback(
    (files) => {
      setPopupActive(true);
      setItemImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : urlPlaceholder,
      );
      let url2 = "";
      const form_data = new FormData();
      form_data.append("content_type", files[files.length - 1].type);
      form_data.append("file_name", files[files.length - 1].name);
      axios
        .post("/admin/v1/images/s3", form_data, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((res) => {
          setItemImageKey(res.data.key);
          url2 = res.data.url;
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": "public-read-write",
                "Content-Type": files[files.length - 1].type,
              },
            })
            .then(() => {
              setPopupActive(false);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },

    [itemImageFiles],
  );

  const handleSave = async () => {
    let proceed = true;
    let errors = fieldRequired;
    if (
      !bundleContent?.title?.en ||
      !bundleContent?.title?.ar ||
      !bundleContent?.description?.en ||
      !bundleContent?.description?.ar
    ) {
      proceed = false;
      if (!bundleContent?.title?.en) {
        errors = {
          ...errors,
          title: {
            ...errors.title,
            en: (
              <InlineError
                message="This field is required"
                fieldID="myFieldID"
              />
            ),
          },
        };
      }
      if (!bundleContent?.title?.ar) {
        errors = {
          ...errors,
          title: {
            ...errors.title,
            ar: (
              <InlineError
                message="This field is required"
                fieldID="myFieldID"
              />
            ),
          },
        };
      }
      if (!bundleContent?.description?.en) {
        errors = {
          ...errors,
          description: {
            ...errors.description,
            en: (
              <InlineError
                message="This field is required"
                fieldID="myFieldID"
              />
            ),
          },
        };
      }
      if (!bundleContent?.description?.ar) {
        errors = {
          ...errors,
          description: {
            ...errors.description,
            ar: (
              <InlineError
                message="This field is required"
                fieldID="myFieldID"
              />
            ),
          },
        };
      }
      if (!proceed) {
        setFieldRequired(errors);
      }
    }

    if (proceed) {
      setFieldRequired({
        title: {
          en: <InlineError message="" fieldID="myFieldID" />,
          ar: <InlineError message="" fieldID="myFieldID" />,
        },
        description: {
          en: <InlineError message="" fieldID="myFieldID" />,
          ar: <InlineError message="" fieldID="myFieldID" />,
        },
      });
      let body = {
        ...bundleContent,
        main_image: mainImageKey,
        item_image: itemImageKey,
      };

      if (id) {
        await updateBundle(id, body);
      } else {
        await postBundle(body);
      }
    }
  };

  useEffect(() => {
    getBundleInfo();
  }, []);

  useEffect(() => {
    if (id) {
      getBundle(id);
    }
  }, []);

  useEffect(() => {
    if (bundleContent) {
      setMainImagePreview(bundleContent.main_image);
      setItemImagePreview(bundleContent.item_image);
    }
  }, [bundleContent]);

  return (
    <Page
      title={id ? "Edit Bundle" : "Add Bundle"}
      breadcrumbs={[{ content: "List Of Bundles", url: "/admin/bundles" }]}
      primaryAction={{ content: "Save", onAction: handleSave }}
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <div>
              <TextStyle variation="strong">English Title</TextStyle>
              <TextField
                value={bundleContent?.title?.en}
                label="English Title"
                labelHidden
                onChange={(value) => handleChange("title", value, "en")}
              />
              {fieldRequired.title.en}
            </div>
            <div>
              <TextStyle variation="strong">Arabic Title</TextStyle>
              <TextField
                labelHidden
                label="Arabic Title"
                value={bundleContent?.title?.ar}
                onChange={(value) => handleChange("title", value, "ar")}
              />
              {fieldRequired.title.ar}
            </div>
          </FormLayout.Group>
          <FormLayout.Group>
            <div>
              <TextStyle variation="strong">Main Image</TextStyle>
              <div
                style={{ display: "flex", width: "100%", columnGap: "10px" }}
              >
                <Thumbnail size="large" source={mainImagePreview} />
                <div style={{ width: "100%" }}>
                  <DropZone
                    onDrop={handleDropZoneDrop}
                    onDropAccepted={sendMainImage}
                  >
                    {uploadedMainFiles}
                    {mainFileUpload}
                  </DropZone>
                </div>
              </div>
            </div>
            <div>
              <TextStyle variation="strong">Item Image</TextStyle>
              <div
                style={{ display: "flex", width: "100%", columnGap: "10px" }}
              >
                <Thumbnail size="large" source={itemImagePreview} />
                <div style={{ width: "100%" }}>
                  <DropZone
                    onDrop={handleDropZoneDrop2}
                    onDropAccepted={sendItemImage}
                  >
                    {uploadedItemFiles}
                    {itemFileUpload}
                  </DropZone>
                </div>
              </div>
            </div>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">English Description</TextStyle>
              <TextField
                labelHidden
                label="English Description"
                value={bundleContent?.description?.en}
                onChange={(value) => handleChange("description", value, "en")}
                spellCheck
              />
              {fieldRequired.description.en}
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Arabic Description</TextStyle>
              <TextField
                labelHidden
                label="Arabic Description"
                value={bundleContent?.description?.ar}
                onChange={(value) => handleChange("description", value, "ar")}
                spellCheck
              />
              {fieldRequired.description.ar}
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Status</TextStyle>
              <Select
                isLoading={loading}
                onChange={(selectedOption) =>
                  handleChange("status", selectedOption.value)
                }
                options={statuesOptions}
                menuPosition="fixed"
                menuPlacement="auto"
                value={
                  statuesOptions.find(
                    (option) => option.value === bundleContent?.status,
                  ) || ""
                }
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Tag</TextStyle>
              <Select
                isLoading={loading}
                onChange={(selectedOption) =>
                  handleChange("tag", selectedOption.value)
                }
                value={
                  tagsOptions.find(
                    (option) => option.value === bundleContent?.tag,
                  ) || ""
                }
                options={tagsOptions}
                menuPosition="fixed"
                menuPlacement="auto"
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Position</TextStyle>
              <TextField
                labelHidden
                type="number"
                label="Position"
                value={bundleContent?.position}
                onChange={(value) => handleChange("position", value)}
              />
            </FormLayout>
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <BundleItem
        shopProductsOptions={shopProductsOptions}
        loading={loading}
        procedureTypesOptions={procedureTypesOptions}
        itemsPositionsOptions={itemsPositionsOptions}
        typeOptions={typeOptions}
        handleChange={handleChange}
        bundleItems={bundleItems}
        addBundleItem={addBundleItem}
        removeBundleItem={removeBundleItem}
        handleBundleItemChange={handleBundleItemChange}
        handleChangePrice={handleChangePrice}
        handleRemovePrice={handleRemovePrice}
        shopProductPrices={shopProductPrices}
      />
      <Modal
        open={popupActive || loading || bundleLoading}
        loading={true}
      ></Modal>
    </Page>
  );
}

export default Bundle;
