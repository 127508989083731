import Select from "react-select";
import { FormLayout, TextStyle } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { capitalizeWords } from "../util/helpers";
import BundleItemPrices from "./bundle-item-prices.component";

function BundleItemOptions({
  bundleItem,
  handleBundleItemChange,
  handleChangePrice,
  handleRemovePrice,
  bundleIndex,
  loading,
  shopProductPrices,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionsData, setOptionsData] = useState(null);
  useEffect(() => {
    if (Array.isArray(shopProductPrices)) {
      const options = shopProductPrices.find(
        (shopProductPrice) => shopProductPrice?.id == bundleItem?.itemType,
      )?.options;

      setOptionsData(options);
      if (options) {
        if (bundleItem?.prices && bundleItem?.prices.length > 0 && !selectedOption) {
          setSelectedOption(
            options.find(
              (option) => option?.id === bundleItem?.prices[0]?.option_id,
            ),
          );
        } else if (options.length === 1) {
          setSelectedOption(options[0]);
          const prices = options[0]?.prices;
          handleBundleItemChange(bundleIndex, "prices", prices);
        } else {
          handleBundleItemChange(bundleIndex, "prices", []);
        }
      }
    }
  }, [bundleItem?.itemType]);

  const handleChangeSelect = (value, bundleIndex) => {
    const getOption = optionsData.find((option) => option?.id === value);
    setSelectedOption(getOption);
    handleBundleItemChange(bundleIndex, "prices", getOption?.prices);
  };

  const shopProductOptions = Array.isArray(optionsData)
    ? optionsData.map((option) => ({
        value: option?.id,
        label: capitalizeWords(option?.value?.en),
      }))
    : [];

  let optionName = "";

  if (selectedOption && selectedOption?.value) {
    optionName = selectedOption?.value?.en;
  }

  return (
    <>
      {shopProductOptions.length > 1 && (
        <div style={{ padding: "10px 0" }}>
          <FormLayout>
            <TextStyle variation="strong">Item Options {optionName}</TextStyle>
            <Select
              label="Type"
              options={shopProductOptions}
              value={
                shopProductOptions.find(
                  (option) => option.value === selectedOption?.id,
                ) || ""
              }
              onChange={(selectedOption) =>
                handleChangeSelect(selectedOption.value, bundleIndex)
              }
              isLoading={loading}
              menuPosition="fixed"
              menuPlacement="auto"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
            />
          </FormLayout>
        </div>
      )}
      <BundleItemPrices
        bundleItem={bundleItem}
        bundleIndex={bundleIndex}
        handleRemovePrice={handleRemovePrice}
        handleChangePrice={handleChangePrice}
      />
    </>
  );
}

export default BundleItemOptions;
